
export const removeLocalStorage = () => {
    localStorage.removeItem("userCart");
    localStorage.removeItem("coupon");
    localStorage.removeItem("discount");
    localStorage.removeItem("discountType");
    localStorage.removeItem("totalCartQty");
 };

 export const removeCookies = () => {
    document.cookie = "orderId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "paymentId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "paymentMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "referenceId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "totalPaidAmount=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
 };