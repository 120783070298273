import axios from "axios"; 
import { handleResponse } from "./handle-response"; 

export const GET = async (url) => {
    // const bearer = "Bearer " + token;
    // const configData = {
    //     headers: { "content-type": "application/json", Authorization: bearer },
    // };
    return axios
        .get(url)
        .then((res) => {
            return handleResponse(res);
        })
        .catch((error) => {
            return handleResponse(error.response);
        });
};

export const POST = async (url, data, token) => {
    const bearer = "Bearer " + token;
    const configData = {
        headers: { "content-type": "application/json", Authorization: bearer },
    };
    return axios
        .post(url, data, configData)
        .then((res) => {
            return handleResponse(res);
        })
        .catch((error) => {
            // return handleResponse(error.response);
            if (error.response.status === 403) {
                return {
                    message: error.response.data.message,
                    code: error.response.status,
                };
            }
        });
};

export const PUT = async (url, data, token) => {
    const bearer = "Bearer " + token;
    const configData = {
        headers: { "content-type": "application/json", Authorization: bearer },
    };
    return axios
        .put(url, data, configData)
        .then((res) => {
            return handleResponse(res);
        })
        .catch((error) => {
            return handleResponse(error.response);
        });
};

export const PATCH = async (url, data, token) => {
    const bearer = "Bearer " + token;
    const configData = {
        headers: { "content-type": "application/json", Authorization: bearer },
    };
    return axios
        .patch(url, data, configData)
        .then((res) => {
            return handleResponse(res);
        })
        .catch((error) => {
            return handleResponse(error.response);
        });
};

export const DELETE = async (url, token) => {
    const bearer = "Bearer " + token;
    const configData = {
        headers: { "content-type": "application/json", Authorization: bearer },
    };
    return axios.delete(url, configData).then(handleResponse);
};
export const LOGOUT = async () => {
    localStorage.clear();
    window.location = "/";
};
      


export const lowerCase = async (stringData) => {
    const str = stringData;
    return str.toLowerCase();
};

export function titleCase(stringData) {
    const str = stringData;
    if (str) {
        const newStr = str.split(' ')
            .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(' ');
        return newStr;
    }
}