import axios from 'axios'; 
import store from '../store';
import { authStatus } from '../action/Status';

const token = window.localStorage.getItem('accessToken');

const axiosIntance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'token': token
    }
});

// axiosIntance.interceptors.request.use((req) => {
//     const { auth } = store.getState();
//     if(auth.token){
//         req.headers.Authorization = `Bearer ${auth.token}`;
//     }
//     return req;
// })

axiosIntance.interceptors.response.use((res) => {
    return res;
}, (error) => { 
    const status = error.response ? error.response.status : 500;
    if(status && status ===400 || status ===500){ 
        localStorage.clear();
        store.dispatch({ type: authStatus.LOGOUT_SUCCESS });
        window.location.href = '/login';
    }
    return Promise.reject(error);
})

export default axiosIntance;