import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import {useEffect} from "react";
import Config from "../../config.json";

export default function Signin(props) {
  const rememberMe = JSON.parse(localStorage.getItem("rememberMe"));
  async function loginUser(credentials) {
    const url = process.env.REACT_APP_BASE_URL + "login";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json());
  }
  const [email, setUserName] = useState(rememberMe?.email);
  const [password, setPassword] = useState(rememberMe?.password);
  const [AlertText, setAlertText] = useState();
  const [Variant, setVariant] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      email,
      password,
    });
    if (response.success == 1) {
      setAlertText(response.message);
      setVariant("success");
      localStorage.setItem("accessToken", response.body.user.token);
      localStorage.setItem("userId", response.body.user._id);
      localStorage.setItem("customerLevelName", response.body.customerLevelName);
      localStorage.setItem("user", JSON.stringify(response.body["user"]));
      localStorage.setItem("totalCartQty", response.body["cartQty"]);
      localStorage.setItem("totalRemainingForNextLevel", response.body["totalRemainingForNextLevel"]);
      localStorage.setItem(
        "userType",
        JSON.stringify({
          applicableDiscount: response.body["applicableDiscount"],
          customerLevelName: response.body["customerLevelName"],
        })
      );
      window.location.href = "/my-account";
    } else {
      setAlertText(response.message);
      setVariant("danger");
    }
  };

  useEffect(() => {
    setUserName(rememberMe?.email);
    setPassword(rememberMe?.password);
  }, []);

  return (
    <main className="main">
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" onClick={() => localStorage.setItem("menuItem", "")}>
                    Home
                  </a>
                </li>
              </ol>
            </div>
          </nav>

          <h1>Login</h1>
        </div>
      </div>

      <div className="container login-container">
        <div className="row">
          <div className="col-lg-10 mx-auto login-page">
            <div className="row">
              <div className="col-md-6 login-style">
                <div className="heading mb-1 text-center ">
                  <h2 className="title">Login</h2>
                </div>

                <form className="mb-0" noValidate onSubmit={handleSubmit}>
                  <label for="login-email">
                    Email adres
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    className="form-input form-wide mb-2"
                    value={email}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <label for=" login-password">
                    Wachtwoord<span className="required"> *</span>
                  </label>

                  <input
                    required
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    className="form-input form-wide mb-2"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="App col-md-12">
                    <Alert variant={Variant} show={AlertText ? true : false}>
                      {AlertText}
                    </Alert>
                  </div>
                  <div className="form-footer">
                    <div className="custom-control custom-checkbox ml-0">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="lost-password"
                        onClick={(e) => {
                          if (e.target.checked == true && email && password) {
                            localStorage.setItem("rememberMe", JSON.stringify({email, password}));
                            setAlertText("");
                            setVariant("");
                          } else {
                            setAlertText("All fields are required");
                            setVariant("danger");
                          }
                          if (e.target.checked == false && (rememberMe != null || rememberMe != [])) {
                            localStorage.removeItem("rememberMe");
                            setAlertText("");
                            setVariant("");
                          }
                        }}
                      />&nbsp;
                      <label className="custom-control-label form-footer-right" for="lost-password">
                        Onthoud mijn gegevens
                      </label>
                    </div>
                    <div className="form-footer-right">
                      <a href="/forgetpass" className="forget-password">
                      Wachtwoord Vergeten?
                      </a>
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" fullWidth variant="contained" color="primary" className="btn btn-dark btn-block btn-md">
                      Log In
                    </button>
                    <a href="/register" type="button" className="btn btn-regist bg-transparent text-transform-none p-0" title="register button">
                      Klant Worden!
                    </a>
                  </div>
                </form>
              </div>
              <div className="col-md-6 login-image">
                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/login.svg`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
