import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import dummyImage from "../../assets/images/placeholder-img-150x150.png"; 
import { Link, useNavigate } from "react-router-dom"; 
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAction, getUserCart } from '../../action/cartActions'; 
import placeholder600by600 from "../../assets/images/placeholder-img-100x100.png"
import Pagination from '../common/Pagination';
import Slider from '@mui/material/Slider'; 
import Loader from '../common/Loader';
import Minipopup from './Minipopup';
import axiosIntance from '../../helper/axios';
import Config from "../../config.json"

export default function Products() {
  
  let salePrice = 0.00;
  let applicableDiscount = JSON.parse(localStorage.getItem("userType"))?.applicableDiscount;
  const location = useLocation();
  const data = location.state;
  const [loader, setLoader] = useState(false);
  const user = localStorage.getItem("user");
  const selectedcat = localStorage.getItem("selectcat");
  const params = useParams();
  const [filtercat, setfiltercat] = useState([selectedcat]);
  const [filterbrand, setfilterbrand] = useState([]);
  const [filtercolor, setfiltercolor] = useState([]);
  const [showMiniPopup, setShowMiniPopup] = useState(false);
  const [productList, setProductList] = useState();
  const [showProducts, setShowProducts] = useState(12);//11
  const [pageNo, setPageNo] = useState(1);
  const [listType, setListType] = useState("grid");
  const [sortList, setSortList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);

  const [productNumber, setProductNumber] = useState();
  const [prodQty, setProdQty] = useState(1);
  const updatedCart = useSelector(state => state?.addedToCart);
  const [newProduct, setNewProduct] = useState([]);
 
  const dispatch = useDispatch();
  const [colorBrandList, setColorBrandList] = useState();
 
  const [incrementValue, setIncrementValue] = useState([]);
  const [randomNo, setRandomNo] = useState();
  const [productColorList, setProductColorList] = useState(null);
 
  const [loading, setLoading] = useState('');
  const [amount, setAmount] = useState([1, 100000]);
  const [wishlistItem, setWishlistItem] = useState([]);
  const userCartState = useSelector(state => state?.getUserCart);

  const [lastCartItem, setLastCartItem] = useState({});

  const userId = localStorage.getItem("userId");
  
  const fetchProductList = async () => {
    setLoader(true);

    const searchParams = new URLSearchParams(document.location.search)
    const searchText  = searchParams.get('q');
    const searchCatId = searchParams.get('cat');


if(searchText || searchCatId){
    var { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}search`, {
      category: searchCatId,
      color: filtercolor?.toString(),
      brand: filterbrand?.toString(),
      page: pageNo,
      limit: showProducts,
      sortFlag: sortList,
      price: amount?.[0]?.toString() + "," + amount?.[1]?.toString(),
      q: searchText,   
    });
  } else {
    
    var { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}get-products`, {
      category: filtercat?.toString(),
      color: filtercolor?.toString(),
      brand: filterbrand?.toString(),
      page: pageNo,
      limit: showProducts,
      sortFlag: sortList,
      price: amount?.[0]?.toString() + "," + amount?.[1]?.toString()
    });
  }

    setProductList(data);
    setProductNumber(data?.body?.totalProducts);
    setLoader(false);


  }

  const fetchCategoryList = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}get-categories`); 
    setCategoryList(data);
  }

  const fetchWishlist = async () => { 
    const { data } = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-wishlists`, { 
      userId
  }); 
    setWishlistItem((data?.body.length > 0 ? data?.body : []));
  }

  const fetchColorBrandList = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}get-filters`);
    setColorBrandList(data);
    setProductColorList(data?.body?.colors?.[0]?.attributeTerms);
  }

  useEffect(() => {
    setfilterbrand([]);
    setfiltercat([selectedcat]);
    setfiltercolor([])
    fetchProductList();
  }, [pageNo, showProducts, listType, sortList]);

  useEffect(() => {
    fetchCategoryList();
    if(userId){
       fetchWishlist();
    }
  }, []);

  useEffect(() => {
    dispatch(getUserCart());
  }, [updatedCart]);

  useEffect(() => {
    fetchColorBrandList();
  }, []);
  const [factive, setActive] = useState(null);
  const [brandactive, setBrandActive] = useState(null);
  const [coloractive, setColorActive] = useState(null);

  const categoryListHandler = (slug, id) => {
    localStorage.removeItem('selectedcat');
    setActive(id);
    var arr = filtercat;
    var barr = filterbrand;
    var carr = filtercolor;
    setfiltercat([]);
    setfilterbrand([]);
    setfiltercolor([]);
    if (!arr.includes(slug)) {
      arr.pop();
      arr.push(slug);            
    } else {
      arr.splice(arr.indexOf(slug), 1);
    }
    setfiltercat(arr)
    fetchProductList();
  }
  const brandListHandler = (id) => {
    setBrandActive(id);
    var arr = filterbrand;

    if (!arr.includes(id)) {
      arr.pop();
      arr.push(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
    }
    setfilterbrand(arr)
    fetchProductList();
  }
  const colorListHandler = (id, cid) => {
    var arr = filtercolor;
    setColorActive(cid);
    if (!arr.includes(id)) {
      arr.pop();
      arr.push(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
    }

    setfiltercolor(arr)
    fetchProductList();
  }

  const addToCartHandler = async (totalPrice, shippingDetails, item, totalQuantity) => {
    if(user){
      let obj = incrementValue.find(o => o.product_id == item.itemId); 
      if (incrementValue == "" || incrementValue == 'undefined') {
        totalQuantity = prodQty;
      } else { 
        totalQuantity = obj.qty;
        item.quantity = obj.qty;
      }
      const type  = 'plus';
      const formData ={
        userId : userId,
        item:item
      };
      setLoading(item?.itemId)
      await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-to-cart`, {formData}).then(res=>{
        localStorage.removeItem("totalCartQty");
        if(res.data.success==1){
          setLoading('')
          totalQuantity = res.data.body.totalCartQty;
          localStorage.setItem("totalCartQty",res.data.body.totalCartQty);
          dispatch(addToCartAction({ totalPrice, shippingDetails, item, totalQuantity, type })); 
        }else{
          setLoading('')
        }
        handleShowPopup();
      }) .catch(error=>{
        console.log(error)
      })
      
    }else{
      window.location.href='/login';
    }
  }
  
  const cartManage = (obj, type) => {
    const index = incrementValue.findIndex(item => item.product_id == obj.product_id);
    if (index < 0) {
      setIncrementValue(oldArray => [...oldArray, obj]);
    } else {
      if (type == "plus") {
        incrementValue[index].qty = parseInt(incrementValue[index].qty) + 1;
      } else { 
        if (parseInt(incrementValue[index].qty) > 1) {
          incrementValue[index].qty = parseInt(incrementValue[index].qty) - 1;
        }
      }
      setIncrementValue(incrementValue);
    }
    setRandomNo(Math.random());
  }

  const onQuantityIncrement = (product_id) => {
    let objres = incrementValue.find(o => o.product_id == product_id);
    var qtyNo = objres?.qty + 1; 

    const obj = {
      qty: (qtyNo ? qtyNo : 2),
      product_id: product_id
    }
    cartManage(obj, 'plus');
  };

  const onQuantityDecrement = (product_id) => { 

    let objres = incrementValue.find(o => o.product_id == product_id);
    var qtyNo = objres?.qty + 1;

    const obj = {
      qty: (qtyNo ? qtyNo : 2),
      product_id: product_id
    }
    cartManage(obj, 'minus');
  };

  function valuePricetext(value) { 
    return `${process.env.REACT_APP_CURRENCY}${value}`;
  }

  const handlePriceChange = (event, newValue) => {
    setAmount(newValue);
    fetchProductList(); 
  };


  const addToWishlistHandler = async (prodId) => {
    const { data } = await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-to-wishlist`, {
      userId,
      itemId: prodId
    });
  }


  const handleShowPopup = () => {
    setShowMiniPopup(true); 
    setTimeout(() => {
      setShowMiniPopup(false);
    }, 3000);
  };

  const handleClosePopup = () => {
    setShowMiniPopup(false);
  };


  console.log(loading)
  return (
    <main className="main caty-page"> 


    {showMiniPopup && (
        <Minipopup productInfo={lastCartItem} onClose={handleClosePopup} />
    )} 


      <div className="container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/" onClick={() => localStorage.setItem("menuItem", "")}>
                <i className="icon-home" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">shop</li>
          </ol>
        </nav>
        <Loader loading={loader} />
        <div className="row main-product-container">
          <div className="col-lg-9 main-content">
            <nav className="toolbox sticky-header" data-sticky-options="{'mobile': true}">
              <div className="toolbox-left">
                <a href="#" className="sidebar-toggle">
                  <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <line x1={15} x2={26} y1={9} y2={9} className="cls-1" />
                    <line x1={6} x2={9} y1={9} y2={9} className="cls-1" />
                    <line x1={23} x2={26} y1={16} y2={16} className="cls-1" />
                    <line x1={6} x2={17} y1={16} y2={16} className="cls-1" />
                    <line x1={17} x2={26} y1={23} y2={23} className="cls-1" />
                    <line x1={6} x2={11} y1={23} y2={23} className="cls-1" />
                    <path d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                    <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                    <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3" />
                    <path d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                  </svg>
                  <span>Filter</span>
                </a>
                <div className="toolbox-item toolbox-sort">
                  <label>Sorteer op:</label>
                  <div className="select-custom">
                    <select name="orderby" className="form-control" 
                      onClick={(e) => setSortList(e.target.value)}> 
                      <option value="">Kies Optie</option>
                      <option value={-1}>Sorteer op prijs: laag naar hoog</option>
                      <option value={1}>Sorteer op prijs: hoog naar laag</option>
                    </select>
                  </div> 
                </div> 
              </div> 
              <div className="toolbox-right">
                <div className="toolbox-item toolbox-show">
                  <label>Show:</label>
                  <div className="select-custom">
                    <select name="count" className="form-control" value={showProducts} onChange={(e) => setShowProducts(e.target.value)}>
                      <option value={12}>12</option>{/* 11 */}
                      <option value={24}>24</option>{/* 23 */}
                      <option value={36}>36</option>{/* 35 */}
                    </select>
                  </div>
                  {/* End .select-custom */}
                </div>
                {/* End .toolbox-item */}
                <div className="toolbox-item layout-modes">
                  <a href="#" className="layout-btn btn-grid active" title="Grid" onClick={() => setListType("grid")} >
                    <i className="icon-mode-grid" />
                  </a>
                  <a href="#" className="layout-btn btn-list" title="List" onClick={() => setListType("list")} >
                    <i className="icon-mode-list" />
                  </a>
                </div> 
              </div> 
            </nav>
            {
              listType == "grid" ? (
                <div className="row products-group products-caty">
                  {
                    productList?.body?.prodcuts?.length != 0 ? productList?.body?.prodcuts?.map((list, id) => {
                    
                      let obj = incrementValue.find(o => o.product_id == list?._id);
                      var qtyval = (obj?.qty ? obj?.qty : 1); 

                      var result = wishlistItem?.find(obj => {
                        return obj.itemId === list?._id
                      })

                      var clName = "btn-icon btn-icon-wish";
                      if(result){
                          clName = "btn-icon btn-icon-wish added-wishlist";
                      }

                      return (
                        <div className="col-md-4 col-6 product-section">
                          <div className="product-default inner-quickview inner-icon quantity-input card p-2">
                            <figure>
                              <Link to={`/product-details/${list.slug}`}>
                                <img src={list.images.length != 0 ? list.images[0] : dummyImage} width={280} height={280} alt="product" />
                              </Link>
                          
                             {
                              user ? (
                                <div className="btn-icon-group"  onClick={() => addToWishlistHandler(list?._id)}>
                                <a href="#" className={clName}>
                                  <i className="icon-heart" />
                                </a>
                              </div>
                              ) : null
                             }
                              <a href={`/product-details/${list.slug}`} className="btn-quickview1" title="Bekijken">Bekijken</a>
                            </figure>
                            <div className="product-details">
                              <div className="category-list">
                                <a href="#" className="product-category">{list.brand}</a>
                              </div>
                              <h3 className="product-title"> <a href={`/product-details/${list.slug}`}>{list.name}</a>
                              </h3>
                              <div className="price-box">
                                <span className="product-price">
                                  { user ?
                                    <>
                                    {   
                                      applicableDiscount ?
                                      (process.env.REACT_APP_CURRENCY + (Number(parseFloat(list?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(list?.salePrice['$numberDecimal'] ))).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                      :
                                      process.env.REACT_APP_CURRENCY+Number(parseFloat(list?.salePrice['$numberDecimal']))
                                    }
                                    </>
                                  :""}
                                </span>
                              </div> 
                              <div className="product-action"> 
                                {/* <div className='product-single-qty'>
                                  <button disabled={(list?.stockQty > 0 ? false : true)}  className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => onQuantityDecrement(list?._id)}>-</button>
                                  <input value={qtyval} className="w-20 bg-sky-50 text-center py-1 mob-inp" readOnly onChange={(e) => setProdQty(e.target.value)} />
                                  <button disabled={(list?.stockQty > 0 ? false : true)}  className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => onQuantityIncrement(list?._id)}>+</button>
                                </div> */}
                                {(list?.stockQty > 0 ? <>
                                  <a
                                className="btn btn-dark add-cart mr-2 btn-icon btn-add-cart product-type-simple"                                
                                  onClick={(e) => { 
                                    let obj = incrementValue.find(o => o.product_id === list?._id);
                                    let qty = (obj?.qty ? obj?.qty : 1);

                                    if(applicableDiscount){
                                      salePrice = parseFloat(list?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(list?.salePrice['$numberDecimal']))
                                    }else{
                                      salePrice = list?.salePrice ? list?.salePrice['$numberDecimal'] : 0;
                                    }

                                    let totalPrice = (Number(salePrice) * Number(qty)).toString();
                                    let shippingDetails = {};
                                    let totalQuantity = qty;  
                                    let item = {
                                      id: uuidv4(),
                                      itemId: list?._id,
                                      thkId:list?.thuiskopieId,
                                      product: list?.name,
                                      quantity: qty,
                                      price: salePrice,
                                      subTotal: totalPrice,
                                      size: "",
                                      imgPath: list?.images?.[0] ? list?.images?.[0] : placeholder600by600,
                                      slug: list.slug,
                                      availableStock:list.stockQty
                                    } 
                                    setLastCartItem(item);
                                    addToCartHandler(totalPrice, shippingDetails, item, totalQuantity)
                                  }}
                                   title="Toevoegen">
                                  <i className="icon-shopping-cart" /> { loading == list?._id ? 'Even geduld aub' : 'Toevoegen'}</a>
                                </> : <>
                                <a
                                className="btn btn-dark add-cart mr-2 btn-icon btn-add-cart1 product-type-simple disabled"  
                                   title="Uitverkocht">
                                  <i className="icon-shopping-cart" />Uitverkocht</a>
                                </>)}
                              </div>
                            </div> 
                          </div>
                        </div>
                      )
                    }) : <>
                        <div className="col-10 mx-auto card-product-not-found">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/nf.webp`} alt=''/>
                                <h3>Sorry, we kunnen het niet vinden</h3>
                        </div>
                    </>
                  }
                </div>
              ) : listType == "list" ? (
                <div className="row pb-4">
                  {
                    productList?.body?.prodcuts?.length != 0 ? productList?.body?.prodcuts?.map((list, id) => {
                      var result = wishlistItem.find(obj => {
                        return obj.itemId === list?._id
                      })

                      var clName = "btn-icon btn-icon-wish";
                      if(result){
                          clName = "btn-icon btn-icon-wish added-wishlist";
                      }

                      return (
                        <div className="col-sm-12 col-6 product-default left-details product-list mb-2 list-group-item">
                          <figure>
                            <Link to={`/product-details/${list.slug}`}>
                              <img src={list.images.length != 0 ? list.images[0] : dummyImage} width="250" height="250"
                                alt="product" />
                              <img src={list.images.length != 0 ? list?.images[0] : dummyImage} width="250" height="250"
                                alt="product" />
                            </Link>
                          </figure>
                          <div className="product-details">
                            <div className="category-list">
                              <a className="product-category">{list?.brand}</a>
                            </div>
                            <h3 className="product-title"> <Link to={`/product-details/${list.slug}`}>{list.name}</Link>
                            </h3>
                            <p className="product-description">{list.shortDescription}</p>
                            <div className="price-box">
                              <span className="product-price">
                                { user ?
                                  <>
                                    {   
                                      applicableDiscount ?
                                      (process.env.REACT_APP_CURRENCY + (Number(parseFloat(list?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(list?.salePrice['$numberDecimal'] ))).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                      :
                                      process.env.REACT_APP_CURRENCY+Number(parseFloat(list?.salePrice['$numberDecimal']))
                                    }
                                  </>
                                  :""}
                              </span>
                            </div> 
                            <div className="product-action">
                            { list?.stockQty > 0 ? 
                            <>
                              <a className="btn-icon btn-add-cart product-type-simple"
                              onClick={(e) => {
                                let obj = incrementValue.find(o => o.product_id === list?._id);
                                let qty = (obj?.qty ? obj?.qty : 1); 
                                
                                if(applicableDiscount){
                                  salePrice = parseFloat(list?.salePrice['$numberDecimal']) - ( (applicableDiscount/100) * parseFloat(list?.salePrice['$numberDecimal']))
                                }else{
                                  salePrice = list?.salePrice ? list?.salePrice['$numberDecimal'] : 0;
                                }

                                let totalPrice = (Number(salePrice) * Number(qty)).toString();
                                let shippingDetails = {};
                                let totalQuantity = qty;  
                                let item = {
                                  id: uuidv4(),
                                  itemId: list?._id,
                                  thkId:list?.thuiskopieId,
                                  product: list?.name,
                                  quantity: qty,
                                  price: salePrice,
                                  subTotal: totalPrice,
                                  size: "",
                                  imgPath: list?.images?.[0] ? list?.images?.[0] : placeholder600by600,
                                  slug: list.slug,
                                  availableStock:list.stockQty
                                } 
                                setLastCartItem(item);
                                addToCartHandler(totalPrice, shippingDetails, item, totalQuantity)
                              }}
                              title="Toevoegen">
                                <i className="icon-shopping-cart"></i>
                                <span>{ loading == list?._id ? 'Even geduld aub' : 'Toevoegen' }</span>
                              </a>
                            </>
                              :
                              <>
                                <a
                                className="btn btn-dark add-cart mr-2 btn-icon btn-add-cart1 product-type-simple disabled"  
                                title="Uitverkocht">
                                <i className="icon-shopping-cart" />&nbsp;&nbsp;&nbsp;Uitverkocht</a>
                              </>
                              }
                              {
                                user ? (
                                  <a href="#" className={clName} title="wishlist" onClick={() => addToWishlistHandler(list?._id)}>
                                <i className="icon-heart"></i>
                              </a>
                                ) : null
                              }
                              <Link to={`/product-details/${list.slug}`} className="btn-quickview" title="Bekijken">
                                <i className="fas fa-external-link-alt"></i>
                              </Link>
                            </div>
                          </div> 
                        </div>
                      )
                    }) : 
                    <>
                        <div className="col-10 mx-auto card-product-not-found">
                                <img src={`${Config.REACT_BASE_URL}/custom/assets/images/nf.webp`} alt=''/>
                                <h3>Sorry, we kunnen het niet vinden</h3>
                        </div>
                    </>
                  }

                </div>
              ) : null
            }
           
            {productNumber > showProducts ?
              <nav className="toolbox toolbox-pagination"> 
                <Pagination
                    setPage={page => setPageNo(page)}
                    pageSize={showProducts}
                    currentPage={pageNo}
                    totalCount={productList?.body?.totalProducts}
                />   
              </nav>
              : null}
          </div>
          {/* End .col-lg-9 */}
          <div className="sidebar-overlay" />
          <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
            <div className="sidebar-wrapper rounded">
              <div className="widget">
                <h3 className="widget-title">
                  <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="false" aria-controls="widget-body-2">Categorieen</a>
                </h3>
                <div className="collapse show" id="widget-body-2">
                  <div className="widget-body">
                    <ul className="cat-list">
                      {
                        categoryList ? categoryList?.body?.map((list, i) => {
                          return ( 
                            <li key={i} >
                              <a href={`#widget-category-${i + 1}`} className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls={`widget-category-${i + 1}`}>
                                {list.name}
                                <span className="toggle" />
                              </a>
                              <div className="collapse" id={`widget-category-${i + 1}`}>
                                <ul className="cat-sublist"> 
                                  {
                                    list?.subCategory?.map((subcat, i) => (
                                      <li href="#" onClick={() => categoryListHandler(subcat.slug, subcat._id)} className={factive == subcat._id && "active"}>{subcat.name}</li>
                                    )
                                    )
                                  }
                                </ul>
                              </div>
                            </li>
                          )
                        }) : null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            
              <div className="widget widget-size">
                <div>
                <h3 className="widget-title">
                  <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Brand</a>
                </h3>
                <div className="collapse show" id="widget-body-5" style={{
                  overflowY: "scroll",
                  height:"150px"
                }} >
                  <div className="widget-body pb-0">
                    <ul className=" cat-list brandlist">
                      {productList?.body?.brandList?.map((brand, id) => {
                        return (
                          <li 
                          className={brandactive == brand && "active"}
                          key={id}>
                            <a href="javascript:void(0)" onClick={() => brandListHandler(brand)} >{brand}</a>
                          </li>
                        )
                      })
                      }
                    </ul>
                  </div>
                </div>
                </div>
              </div>
            

              <div className='widget widget-size'>
                <div className="color">
                  <h3 className="widget-title">
                    <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Kleur</a>
                  </h3>
                  <div className="collapse show" id="widget-body-3">
                    <div className="widget-body pb-0 custom-scl">
                      <ul className="cat-list brandlist">
                        {
                          productColorList?.map((listcolor, id) => {
                            return (
                              <li className={coloractive == listcolor._id && "active"}>
                                <a href="javascript:void(0)" onClick={() => colorListHandler(listcolor.slug, listcolor._id)} >{listcolor.name}</a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>                
              </div>    

              <div className='widget widget-size'>
                <div className="color">
                  <h3 className="widget-title">
                    <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Prijs</a>
                  </h3>
                  <div className="collapse show" id="widget-body-3">
                    <div className="widget-body pb-0 custom-scl priceslide"> 
                    <Slider
                          getAriaLabel={() => 'Price range'}
                          defaultValue={amount}
                          valueLabelDisplay="auto"
                          min={1}
                          max={100000}
                          onChange={handlePriceChange}
                          value={amount}
                          getAriaValueText={valuePricetext}
                        />
                    </div>
                  </div>
                </div>                
              </div>    
            </div> 
          </aside> 
        </div> 
      </div> 
    </main>
  )
}