import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import moment from 'moment/moment';
import axiosIntance from '../../helper/axios';
import DeleteModelPopup from "../common/DeleteModelPopup";
import Loader from '../common/Loader';
import bImg from "../../assets/images/br.jfif";
import sImg from "../../assets/images/ss.jfif";
import gImg from "../../assets/images/ggg.jfif";
import dImg from "../../assets/images/d3.png";
import Config from "../../config.json"

 
export default function Myaccount() {

  const [companyName, setCompany_Name] = useState();
  const [title, setTitle] = useState();
  const [personName, setPerson_Name] = useState();
  const [email, setPerson_Email] = useState();
  const [phone, setPerson_Phonenumber] = useState();
  const [profileaddress, setPerson_Address] = useState();
  const [profilecountry, setPerson_Country] = useState();
  const [profilecity, setPerson_City] = useState();
  const [profilestate, setPerson_State] = useState();
  const [profilezipcode, setPerson_Zipcode] = useState();
  const [shippingAddress, setPerson_Shipping_Address] = useState();
  const [shippingCountry, setpPerson_Shipping_Country] = useState();
  const [shippingCity, setPerson_Shipping_City] = useState();
  const [shippingState, setPerson_Shipping_State] = useState();
  const [shippingZipcode, setPerson_Shipping_Zipcode] = useState();
  const [commerceNumber, setPerson_Commerce_Number] = useState();
  const [vatNumber, setPerson_Vat_Number] = useState();
  // const [kvkNumber, setKvkNumber] = useState(user?.commerceNumber);
  const [commerceNumberAnnex, setCommerceNumberAnnex] = useState();
  const [bankName, setPerson_Bank_Name] = useState();
  const [accountNumber, setPerson_Account_Number] = useState();
  const [swiftNumber, setPerson_Swift_Number] = useState();

  const userData = JSON.parse(localStorage.getItem('user'));
  const [userId, setUserID] = useState(userData?._id);
  
  const [oldPassword, setOldPassword] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [showOrders, setShowOrders] = useState(10);
  const [orderId, setOrderID] = useState('');
  const [ openModal, setOpenModal ] = useState( false );

  const [select, setSelected] = useState('');
  const [optionList, setOptionList] = useState([]);
  const [orderlist, setOrderList] = useState([]);
  const [orderListCount, setOrderListCount] = useState();
  const [orderListFinalCount, setOrderListFinalCount] = useState();
  const [addresslist, setAddressList] = useState([]);
  const [billaddresslist, setBillAddressList] = useState([]); 
  const [addId, setAddressId] = useState('');
  const [address, setbill_Address] = useState();
  const [country, setbill_Country] = useState();
  const [city, setbill_City] = useState();
  const [state, setbill_State] = useState();
  const [zipcode, setbill_Zipcode] = useState();
  const [addressType, setbill_addressType] = useState('billing');
  const [AlertText, setAlertText] = useState();
  const [Variant, setVariant] = useState("");
  const [getEditableIndex, setGetEditableIndex] = useState("");
  const [loader, setLoader] = useState(false);
  const [orderStatus,setOrderStatus] = useState('')

  async function fetchUserDetails() { 
   const userData = JSON.parse(localStorage.getItem('user'));
    const url = process.env.REACT_APP_BASE_URL + 'get-profile';
    const obj = {
      userId:userData._id
    }
    return axiosIntance.post(url, obj) 
      .then(res => { 
        let user = res?.data?.body; 
        setCompany_Name(user?.companyName);
        setTitle(user?.title);
        setPerson_Name(user?.personName);
        setPerson_Email(user?.email);
        setPerson_Phonenumber(user?.phone);
        setPerson_Address(user?.address);
        setPerson_Country(user?.country);
        setPerson_City(user?.city);
        setPerson_State(user?.state);
        setPerson_Zipcode(user?.zipcode);
        setPerson_Shipping_Address(user?.shippingAddress);
        setpPerson_Shipping_Country(user?.shippingCountry);
        setPerson_Shipping_City(user?.shippingCity);
        setPerson_Shipping_State(user?.shippingState);
        setPerson_Shipping_Zipcode(user?.shippingZipcode);
        setPerson_Commerce_Number(user?.commerceNumber);
        setPerson_Vat_Number(user?.vatNumber);
        setCommerceNumberAnnex(user?.commerceNumberAnnex);
        setPerson_Bank_Name(user?.bankName);
        setPerson_Account_Number(user?.accountNumber);
        setPerson_Swift_Number(user?.swiftNumber); 
        setUserID(user?._id);
      })
  }

  async function Userbillingaddress(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'add-address';
    return axiosIntance.post(url, credentials)
      .then(data => data.data)
  }

  async function Updatebillingaddress(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'address-update';
    return axiosIntance.post(url, credentials)
      .then(data => data.data)
  }

  async function UpdatePassword(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'password-update';
    return axiosIntance.post(url, credentials)
      .then(data => data.data)
  }

  async function Deletebillingaddress(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'address-delete';
    return axiosIntance.post(url, credentials).then(data => data.data)
  }

  async function logout(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'logout';
    return axiosIntance.post(url, credentials)
      .then(data => data.data)
  }

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("userCart");
    localStorage.removeItem("menuItem");
    localStorage.removeItem("userType");
    localStorage.removeItem("customerLevelName");
    window.location.href = "/login";

    const response = await logout(userData?.token);
    if (response.success == 1) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("user");
      localStorage.removeItem("userCart");
      localStorage.removeItem("userType");
      localStorage.removeItem("customerLevelName");
      window.location.href = "/login";
    }
  };

  async function UpdateUser(credentials) {
    const url = process.env.REACT_APP_BASE_URL + 'profile-update';
    return axiosIntance.patch(url, credentials)
      .then(data => data.data)
  }

  const orderurl = process.env.REACT_APP_BASE_URL + 'get-orders';
  const cuntryurl = process.env.REACT_APP_BASE_URL + 'get-countries';
  const addressurl = process.env.REACT_APP_BASE_URL + 'get-address-list'; 
  const userlevelurl = process.env.REACT_APP_BASE_URL + 'get-user-level'; 

  const [newinput, setInput] = useState({
    newPassword: '',
    oldPassword: '',
    cpassword: '',
  });

  const [error, setError] = useState({
    newPassword: '',
    cpassword: '',
  });
  

  const handleregupdate = async e => {
    e.preventDefault();
    const obj = {
      _id: userId,
      companyName: companyName,
      personName: personName,
      email: email,
      phone: phone,
      address: profileaddress,
      country: profilecountry,
      city: profilecity,
      state: profilestate,
      zipcode: profilezipcode,
      shippingAddress: shippingAddress,
      shippingCountry: shippingCountry,
      shippingCity: shippingCity,
      shippingState: shippingState,
      shippingZipcode: shippingZipcode,
      commerceNumber: commerceNumber,
      vatNumber: vatNumber,
      bankName: bankName,
      accountNumber: accountNumber,
      swiftNumber: swiftNumber,
      title: title
    }
    const response = await UpdateUser(obj);
    if (response.success == 1) {
      setAlertText(response.message);
      setVariant("success");
      //alert(response.message)
    }
    else {
      setAlertText(response.message);
      setVariant("danger");
      //alert(response.message)
    }
  }
  const handlebillingaddressSubmit = async e => {
    e.preventDefault();
    const response = await Userbillingaddress({
      userId,
      country,
      address,
      city,
      state,
      zipcode,
      addressType

    });
    if (response.success == 1) {
      setAlertText(response.message);
      setVariant("success");
      //alert(response.message)
      window.location.reload();
    }
    else {
      setAlertText(response.message);
      setVariant("danger");
      //alert(response.message)
    }
  }
  const handleaddressupdate = async e => {
    e.preventDefault();
    const response = await Updatebillingaddress({
      addId,
      country,
      address,
      city,
      state,
      zipcode,
      addressType

    });
    if (response.success == 1) {
      setAlertText(response.message);
      setVariant("success");
      //alert(response.message)

    }
    else {
      setAlertText(response.message);
      setVariant("danger");
      //alert(response.message)
    }
  }
  const handleaddressdelete = async (id) => { 
    const response = await Deletebillingaddress({
      addId:id
    });
    if (response.success == 1) {
      setAlertText(response.message);
      setVariant("success");
    }
    else {
      setAlertText(response.message);
      setVariant("danger");
    }
    // window.location.reload();
  }
  const fetchcorderData = () => {
    setLoader(true)
    axiosIntance
      .post(orderurl, {
        userId: userId,
        page: pageNo,
        limit: showOrders
      })
      .then((response) => {
       
        const { data } = response;
        if (response.data.success === 1) {
          setLoader(false)
          //check the api call is success by stats code 200,201 ...etc
          setOrderList(data.body.orders);
          setOrderListFinalCount(data.body.totalOrders)
        } else {
          setLoader(false)
        }
      })
      .catch((error) => {});
  };
  const handleLoadMore = () => {
    if(orderListFinalCount > orderlist?.length) {
      axiosIntance
      .post(orderurl, {
        userId: userId,
        page: pageNo + 1,
        limit: showOrders
      })
      .then((response) => {
        const { data } = response;
        if (response.data.success === 1) {
          //check the api call is success by stats code 200,201 ...etc
          setOrderList(prevList => [...prevList, ...data.body.orders]);
          setOrderListCount(data.body.totalOrders)
        } else {
          //error handle section
        }
      })
      .catch((error) => {});
    } else return;
  }
  const fetchcaddressData = () => {
    axiosIntance
      .post(addressurl, {
        userId: userId
      })
      .then((response) => {
        const { data } = response;
        if (response.data.success === 1) {
          //check the api call is success by stats code 200,201 ...etc
          setAddressList(data.body.shippingAddress);
          setBillAddressList(data.body.billingAddress)

        } else {
          //error handle section
        }
      })
      .catch((error) => {});
  };

  const fetchUserLevelData = () => {
      localStorage.removeItem("totalRemainingForNextLevel");

    axiosIntance
      .post(userlevelurl, {
        userId: userId
      })
      .then((response) => {
        let ul = response?.data?.body?.totalRemainingForNextLevel
          localStorage.setItem("totalRemainingForNextLevel", ul);
      })
      .catch((error) => {});
  };

  const fetchccountryData = () => {
    axiosIntance
      .get(cuntryurl)
      .then((response) => {
         
        const { data } = response;
        if (response.data.success === 1) {
          //check the api call is success by stats code 200,201 ...etc
          setOptionList(data.body)
        } else {
          //error handle section
        }
      })
      .catch((error) =>{});
  };

  const handlepasswordupdate = async e => {
    e.preventDefault();
    const obj = {
      newPassword: newinput.newPassword,
      oldPassword: newinput.oldPassword,
      userId: userId
    }
    const response = await UpdatePassword(obj);
    if (response.success == 1) {
      setAlertText("Password has been Updated successfully");
      setVariant("success");
    }
    else {
      setAlertText(response.message);
      setVariant("danger");
    }
  }
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: '' };
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/ 
      const validateNewPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;
      let validatedNewPassword = true;
      if(!validateNewPassword.test(value)){
        validatedNewPassword = false
      } else {
        validatedNewPassword = true
      }
      switch (name) {
        
        case 'newPassword':
          if (!value) {
            stateObj[name] = 'Voer wachtwoord in alstublieft.';
          } else if (newinput.oldPassword == newinput.newPassword) {
            stateObj['newPassword'] =
              'Nieuw wachtwoord en oud wachtwoord mogen niet overeenkomen.';
          } else if (!validatedNewPassword) {            
            stateObj['newPassword'] =
              'Het wachtwoord moet minimaal zes tekens en maximaal 16 tekens lang zijn. Het moet een kleine letter, een hoofdletter en een speciaal teken bevatten.';
          } else if (newinput.cpassword && value !== newinput.cpassword) {
            stateObj['cpassword'] =
              'Nieuw wachtwoord en wachtwoord bevestigen komen niet overeen.';
          } else {
            stateObj['cpassword'] = newinput.cpassword
              ? ''
              : error.cpassword;
          }
          break;

        case 'cpassword':
          if (!value) {
            stateObj[name] = 'Voer het wachtwoord in. Bevestig het wachtwoord.';
          } else if (newinput.newPassword && value !== newinput.newPassword) {
            stateObj[name] = 'Nieuw wachtwoord en wachtwoord bevestigen komen niet overeen.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const showModel = (e, oId)=>{
    e.preventDefault();
    setOrderID(oId);
    setOpenModal(true)
  }

  async function deleteConfirm ( result ) {
    setOpenModal( false );
    let oId = orderId;
    if(result){
      await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}order-delete`, { userId:userId,orderId:oId })
        .then(result=>{

            if(result.data.success==1){
              setOrderStatus(result.data.message);
              setTimeout(() => {
                window.location.reload()
              }, 2000);
            }
        }).catch(error=>{
            console.log("Error:",error);
        })
    }
}

  useEffect(() => {
    fetchccountryData();
    fetchUserDetails();
    localStorage.setItem("menuItem", "my-account")
  }, [])
  useEffect(() => {
    fetchcorderData();
  }, [pageNo])
  
  useEffect(() => {
    fetchcaddressData();
  }, [])

  useEffect(() => {
    fetchUserLevelData();
  }, [])

  useEffect(() => {
    const array = addresslist[getEditableIndex];
    setbill_Country(array?.countryId);
    setbill_City(array?.city);
    setbill_State(array?.state);
    setbill_Address(array?.address);
    setbill_Zipcode(array?.zipcode);
    setAddressId(array?.addressId);
  }, [getEditableIndex])

  // useEffect(() => {
  //   const array = addresslist[getDeleteableIndex];
  //   setAddressId(array?.addressId);
  // }, [getDeleteableIndex])


  const customerLevelName = localStorage.getItem('customerLevelName');
  let bgColor = '';
  if(userData.customerLevel == "6475bc5b41730509a43ab61e"){
    bgColor = {
      backgroundImage: `url(${ bImg })`,
    }
  }
  else if(userData.customerLevel == '64784c23b6cce37302dd94e4'){
    bgColor = {
      backgroundImage: `url(${ sImg })`,
    }
  }
  else if(userData.customerLevel == '6475c2683a0cb6eba7a8db89'){
    bgColor = {
      backgroundImage: `url(${ gImg })`,
    }
  }
  else if(userData.customerLevel == '6475c26e3a0cb6eba7a8db8d'){
    bgColor = {
      backgroundImage: `url(${ dImg })`,
    }
  }
 

  return (
    <main className="main">
      <Loader loading={loader} />
      <div className="page-header" style={bgColor}>
        <div className='user-level-div'>
        <p className='user-level-para'>Bestel nog voor  <span style={{color:"green"}}>{process.env.REACT_APP_CURRENCY} { Number( localStorage.getItem('totalRemainingForNextLevel') ).toFixed(2)} </span> om het volgende niveau te bereiken !</p>
        </div>
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/" onClick={() => localStorage.setItem("menuItem", "")} style={{color:"#fdffff"}}>Home</a></li>
              </ol>
            </div>
          </nav>
          <h1 style={{color:'#308B7B'}}>Mijn Account  <br/>{(customerLevelName?.trim() !=='null' ? <><span style={{color:"#17202A"}}>{customerLevelName}</span></> : '' )} </h1>
        </div>
      </div>
      <div className="container account-container custom-account-container">
        <div className="row">
          <div className="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0 border">
          <div className="user-profile mb-4">
            <img src={`${Config.REACT_BASE_URL}/custom/assets/images/icons/user.png`} className="mr-2"/>
            <span>{userData?.companyName}</span>
          </div>

            <h2 className="text-uppercase" >Mijn Account</h2>
            <ul className="nav nav-tabs list flex-column mb-0" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="dashboard-tab" data-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="true"><i className='fas fa-home mr-2'/>Dashboard</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="order-tab" data-toggle="tab" href="#order" role="tab" aria-controls="order" aria-selected="true"><i className='fas fa-shopping-bag mr-2'/>Bestellingen</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false"><i className='fas fa-address-card mr-2'/>Adressen</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="edit-tab" data-toggle="tab" href="#edit" role="tab" aria-controls="edit" aria-selected="false"><i className="fas fa-user mr-2"/>Account Gegevens</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/wishlist"><i className="fas fa-heart mr-2"/>Verlanglijst</a>
              </li>
              <li className="nav-item">
                <a style={{cursor:"pointer"}} className="nav-link" onClick={handleLogout}><i className="fas fa-sign-out-alt mr-2"/>Uitloggen</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 order-lg-last order-1 tab-content">
            <div className="tab-pane fade show active" id="dashboard" role="tabpanel">
              <div className="dashboard-content">
                <div className="box-random" />
                <div className="row row-lg">
                  <div className="col-6 col-md-4 box-account">
                    <div className="feature-box icon-myaccount text-center pb-4">
                      <a href="#order" className="link-to-tab"><i className="fas fa-shopping-bag"></i></a>
                      <div className="feature-box-content">
                        <h3>BESTELLINGEN</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-4 box-account">
                    <div className="feature-box icon-myaccount text-center pb-4">
                      <a href="#address" className="link-to-tab"><i className="far fa-address-card"></i></a>
                      <div className="feature-box-content">
                        <h3>ADRESSEN</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 box-account">
                    <div className="feature-box icon-myaccount text-center pb-4">
                      <a href="#edit" className="link-to-tab"><i className="icon-user-2" /></a>
                      <div className="feature-box-content p-0">
                        <h3>ACCOUNT GEGEVENS</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 box-account">
                    <div className="feature-box icon-myaccount text-center pb-4">
                      <a href="/wishlist"><i className="far fa-heart"></i></a>
                      <div className="feature-box-content">
                        <h3>VERLANGLIJST</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 box-account">
                    <div className="feature-box icon-myaccount text-center pb-4">
                      <a onClick={handleLogout}><i className="fas fa-sign-out-alt"></i></a>
                      <div className="feature-box-content">
                        <h3>UITLOGGEN</h3>
                      </div>
                    </div>
                  </div>
                </div>{/* End .row */}
              </div>
            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="order" role="tabpanel">
              <div className="order-content">
                <h3 className="account-sub-title d-none d-md-block"><i className="sicon-social-dropbox align-middle mr-3" />Bestellingen</h3>
                <div className="order-table-container text-center">
                  <table className="table table-order text-left">
                    <thead>
                      <tr>
                        <th className="order-id">Order</th>
                        <th className="order-date">Order Datum</th>
                        <th className="order-status">Status</th>
                        <th className="order-price">Totaal</th>
                        <th className="order-action">Handelingen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderlist?.map((item) => (
                        item?.id?.length > 0
                          ? (
                            <tr className='tr-clr'>
                              <td className="edits-order order-id">{item?.orderId}</td>
                              <td className="edits-order order-date">{ moment(item?.date).format('MMMM DD , YYYY') }</td>
                              <td className="edits-order order-status">{item?.orderStatus}</td>
                              <td className="edits-order order-price">{process.env.REACT_APP_CURRENCY}{(Number(item?.totalAmount).toFixed(2))}</td>
                              <td className="edits-order order-action">
                                <a href={`order-details/${item?.id}`} title="Weergave"><i className="fa fa-eye" />
                                </a> <a href='' onClick ={e=>showModel(e, item?.id)} title="Verwijderen" data-toggle="modal" data-target="#orderDeleteModalCenter"><i className="fa fa-trash" /></a>
                              </td>
                            </tr>) : (
                            <tr>
                              <td className="text-center p-0" colSpan={5}>
                                <p className="mb-5 mt-5">
                                  Er is nog geen bestelling geplaatst.
                                </p>
                              </td>
                            </tr>
                          )
                      ))}{ }
                    </tbody>
                  </table>
                  
                  <hr className="mt-0 mb-3 pb-2" />
                  {(orderlist.length > 0 ? <>
                    <a href="/products" onClick={() => localStorage.setItem("menuItem", "products")} className="btn btn-dark go">Verder Winkelen</a>
                    <a  onClick={() => handleLoadMore()} className="btn btn-dark go" >Meer Laden</a>
                  </> : <>
                    <p className="mb-5 mt-5">
                      Er is nog geen bestelling geplaatst.
                    </p>
                  </>)}

                </div>
              </div>

              <DeleteModelPopup onClose={ deleteConfirm } oStatus={orderStatus}/>

            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="download" role="tabpanel">
              <div className="download-content">
                <h3 className="account-sub-title d-none d-md-block"><i className="sicon-cloud-download align-middle mr-3" />Downloaden</h3>
                <div className="download-table-container">
                  <p>Er zijn nog geen downloaden beschikbaar.</p> <a href="category.html" className="btn btn-primary text-transform-none mb-2">Ga Winkelen</a>
                </div>
              </div>
            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="address" role="tabpanel">
              <h3 className="account-sub-title"><i className="sicon-location-pin align-middle mr-3" />Adressen</h3>
              <div className="addresses-content">
                <p className="mb-4">
                De volgende adressen worden gebruikt op de afrekenpagina door standaard.
                </p>
                <div className="row">
                  <div className="address col-md-6">
                    <div className="heading d-flex">
                      <h4 className="text-dark mb-0">Factuuradres</h4>
                    </div>

                      {billaddresslist.map((billrecord, i) => (
                        billrecord.address.length > 0
                          ? (<div className="col-lg-6 col-md-12 col-xs-12 border p-3 mb-2 ml-4">
                            <div className="ship-content">
                              <div>{billrecord.address}</div>
                              <div><span>{billrecord.countryName}</span>
                                <span>{billrecord.state}</span>
                                <span>{billrecord.city}</span>
                                <span>{billrecord.zipcode}</span></div>
                            </div>
                          </div>)
                          : (
                            <div>U heeft dit type adres nog niet ingesteld.</div>
                          )
                      ))}

                    <div className="heading d-flex">
                      <h4 className="text-dark mb-0">Standaard Afleveradres</h4>
                    </div>

                    <div className="col-lg-6 col-md-12 col-xs-12 border p-3 mb-2 ml-4">       
                      <div className="ship-content">
                        <div>{userData.shippingAddress}</div>
                        <div><span>{userData.shippingCity}</span><span>{userData.shippingState}</span><span>{userData.shippingZipcode}</span></div>
                      </div>                        
                    </div>
                    
                    <div className="heading d-flex">
                      <h4 className="text-dark mb-0">Afleveradres</h4>
                    </div>

                    <div className="row">
                      {addresslist.length > 0 ?
                      <>
                        {addresslist.map((record, i) => (
                          record.address.length > 0
                            ? (
                              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 border p-3 ml-5 mb-2"> 
                              <div className="ship-content">
                                <div>{i + 1}. {record.address}</div>
                                <div><span>{record.countryName}</span><span>{record.state}</span><span>{record.city}</span><span>{record.zipcode}</span></div>
                              </div>
                              <a href="#update-address" className="btn btn-default address-action link-to-tab" onClick={() => { setGetEditableIndex(i) }}>Adres Bijwerken</a>
                                &nbsp;&nbsp;
                              <a className="btn btn-default address-action link-to-tab" onClick={() => { handleaddressdelete(record.addressId) }}>Adres Verwijderen</a>
                            </div>)
                            : (
                              <div>U heeft dit type adres nog niet ingesteld.</div>
                            )
                        ))}
                        </>
                        : "Geen afleveradres beschikbaar." }
                   </div>
                  
                    <a href="#billing" className="btn btn-default address-action link-to-tab">Adres Toevoegen</a>
                  </div>
                </div>
              </div>
            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="edit" role="tabpanel">
              <h3 className="account-sub-title d-none d-md-block mt-0 pt-1 ml-1"><i className="icon-user-2 align-middle mr-3 pr-1" />Account Gegevens</h3>
              <div className="account-content">
                <form action="#" className="mb-0" noValidate onSubmit={handleregupdate}>
                  <div className='form-styl-fullwidth'>
                    <label for="login-company-name" className="form-label  ">Bedrijfsnaam<span className="required">*</span></label>
                    <input type="text" className="form-control" id="companyName" name="companyName" value={companyName} onChange={e => setCompany_Name(e.target.value)} required />

                    <label htmlFor="title" className="form-label  ">Funtie<span className="required">*</span></label>
                    <input type="text" className="form-control" id="title" name="title" value={title} onChange={e => setTitle(e.target.value)} required />

                    <label for="login-person-name" className="form-label text-center">Naam<span className="required">*</span></label>
                    <input type="text" className="form-control" id="personName" name="personName" value={personName} onChange={e => setPerson_Name(e.target.value)} required />
                  </div>
                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="login-email">E-mail Adres<span className="required">*</span></label>
                      <input type="email" className="form-input form-wide" id="email" name="email" value={email} onChange={e => setPerson_Email(e.target.value)} required readOnly />
                    </div>
                    <div className='inner-style'>
                      <label for="login-phonenumber" className="form-label text-center">Telefoon Nummer<span className="required">*</span></label>
                      <input type="tel" className="form-control" id="phone" name="phone" value={phone} onChange={e => setPerson_Phonenumber(e.target.value)} required />
                    </div>
                  </div>
                  <div className='form-styl-fullwidth'>
                    <label for="login-address" className="form-label text-center">Adres<span className="required">*</span></label>
                    <input type="text" className="form-control" id="address" name="address" disabled value={profileaddress} onChange={e => setPerson_Address(e.target.value)} required />
                  </div>

                  <div className='form-styl'>
                  <div className='inner-style'>
                    <label for="login-country" className="form-label" >Land<span className="required">*</span></label>
                    <select
                      className="form-control"
                      id="profilecountry"
                      name="profilecountry"
                      disabled
                      value={select}
                      onChange={e => setPerson_Country(e.target.value)}
                      required
                    >
                      {optionList.map((item) => (
                        <option value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                    <div className='inner-style'>
                      <label for="login-city" className="form-label ">Stad<span className="required">*</span></label>
                      <input type="text" className="form-control " id="city" disabled name="city" value={profilecity} onChange={e => setPerson_City(e.target.value)} required />
                    </div>
                  </div>

                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="login-state" className="form-label text-center">Staat</label>
                      <input type="text" className="form-control" id="state" name="state" disabled value={profilestate} onChange={e => setPerson_State(e.target.value)} />
                    </div>
                    <div className='inner-style'>
                      <label for="zipcode" className="form-label ">Postcode<span className="required">*</span></label>
                      <input type="text" className="form-control" id="zipcode" name="zipcode" disabled value={profilezipcode} onChange={e => setPerson_Zipcode(e.target.value)} required />
                    </div>
                  </div>

                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="shippingAddress" className="form-label text-center">Afleveradres<span className="required">*</span></label>
                      <input type="text" className="form-control" id="shippingAddress" name="shippingAddress" value={shippingAddress} onChange={e => setPerson_Shipping_Address(e.target.value)} required />
                    </div>
                    <div className='inner-style'>
                      <label for="login-shipping-country" className="form-label text-center">Afleveradres Land<span className="required">*</span></label>
                      <select
                        className="form-control"
                        id="shippingCountry"
                        name="shippingCountry"
                        disabled={false}
                        value={shippingCountry}
                        onChange={e => setpPerson_Shipping_Country(e.target.value)}
                        required
                      >
                        {optionList.map((item) => (
                          <option value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="login-shipping-city" className="form-label">Afleveradres Stad<span className="required">*</span></label>
                      <input type="text" className="form-control" id="shippingCity" name="shippingCity" value={shippingCity} onChange={e => setPerson_Shipping_City(e.target.value)} required />
                    </div>
                    <div className='inner-style'>
                      <label for="login-shipping-state" className="form-label text-center">Afleveradres Staat</label>
                      <input type="text" className="form-control" id="shippingState" name="shippingState" value={shippingState} onChange={e => setPerson_Shipping_State(e.target.value)} />
                    </div>
                  </div>
                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="login-shipping-zipcode" className="form-label">Afleveradres Postcode<span className="required">*</span></label>
                      <input type="text" className="form-control" id="shippingZipcode" name="shippingZipcode" value={shippingZipcode} onChange={e => setPerson_Shipping_Zipcode(e.target.value)} required />
                    </div>
                    <div className='inner-style'>
                      <label for="login-commerce-number" className="form-label text-center">Handel Nummer<span className="required">*</span></label>
                      <input type="text" className="form-control" id="commerceNumber" name="commerceNumber" value={commerceNumber} onChange={e => setPerson_Commerce_Number(e.target.value)} required disabled />
                    </div>
                  </div>

                  <div className='form-styl-fullwidth'>
                    <label for="login-vat-number" className="form-label">VAT Nummer<span className="required">*</span></label>
                    <input type="text" className="form-control" id="vatNumber" name="vatNumber" value={vatNumber} onChange={e => setPerson_Vat_Number(e.target.value)} required />

                    <a href={commerceNumberAnnex} download target="_blank" style={{cursor:"pointer"}}>
                      <label htmlFor="commerceNumberAnnex" className="form-label">Handelsdocument Downloaden ( Klik op de link om te downloaden )</label>
                    </a>
                    <br/>
                    <label for="login-bank-name" className="form-label text-center">Bank Naam<span className="required">*</span></label>
                    <input type="text" className="form-control mb-2" id="bankName" name="bankName" value={bankName} onChange={e => setPerson_Bank_Name(e.target.value)} required />

                  </div>
                  <div className='form-styl'>
                    <div className='inner-style'>
                      <label for="login-account-number" className="form-label">Account Nummer<span className="required">*</span></label>
                      <input type="text" className="form-control" id="accountNumber" name="accountNumber" value={accountNumber} onChange={e => setPerson_Account_Number(e.target.value)} required />
                    </div>
                    <div className='inner-style'>
                      <label for="login-swift-number" className="form-label text-center">Swift Nummer<span className="required">*</span></label>
                      <input type="text" className="form-control" id="swiftNumber" name="swiftNumber" value={swiftNumber} onChange={e => setPerson_Swift_Number(e.target.value)} required />
                    </div>
                  </div>

                  <div className="form-footer mt-3 mb-0">
                    <button type="submit" className="btn btn-dark mr-0">
                      Opslaan
                    </button>
                  </div>
                </form>
                <div className="App col-md-12">
                  <Alert variant={Variant} show={AlertText ? true : false}>
                    {AlertText}
                  </Alert>
                </div>
                <form action="#" onSubmit={handlepasswordupdate}>
                  <div className="change-password">
                    <h3 className="text-uppercase mb-2">Wachtwoord Wijzigen</h3>
                    <div className="form-group">
                      <label htmlFor="acc-password">Huidig Wachtwoord (laat dit leeg om het ongewijzigd te laten)</label>
                      <input type="password" className="form-control" id="oldPassword" name="oldPassword" onChange={onInputChange} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="acc-password">Nieuw Wachtwoord (laat dit leeg om het ongewijzigd te laten)</label>
                      <input type="password" className="form-control" id="newPassword" name="newPassword" value={newinput.newPassword}
                        onChange={onInputChange}
                        onBlur={validateInput} required />
                      {error.newPassword && <span className="err" style={{ color: "red" }} >{error.newPassword}</span>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="acc-password">Bevestig Nieuw Wachtwoord</label>
                      <input type="password" className="form-control" id="cpassword" name="cpassword" value={newinput.cpassword}
                        onChange={onInputChange}
                        onBlur={validateInput} required />
                      {error.cpassword && (
                        <span className="err" style={{ color: "red" }} >{error.cpassword}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-footer mt-3 mb-0">
                    <button type="submit" className="btn btn-dark mr-0">
                      Opslaan
                    </button>
                  </div>
                </form>
              </div>
            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="update-address" role="tabpanel">
              <div className="address account-content mt-0">
                <h4 className="title">Afleveradres</h4>
                <div className="App col-md-12">
                  <Alert variant={Variant} show={AlertText ? true : false}>
                    {AlertText}
                  </Alert>
                </div>
                <form className="mb-2" action="#" onSubmit={handleaddressupdate}>
                  <div className="select-custom">
                    <label>Land <span className="required">*</span></label>
                    <select className="form-control"
                      id="country"
                      name="country"
                      disabled={false}
                      onChange={e => setbill_Country(e.target.value)}
                      required 
                      value={country}
                    >
                      <option value="">
                        Select land
                      </option>
                      {optionList.map((item) => (
                        <option value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Adres <span className="required">*</span></label>
                    <input type="text" name="bill-address" id="bill-address" className="form-control" placeholder="Straatnaam + huisnummer" value={address} onChange={e => setbill_Address(e.target.value)} required />
                  </div>
                  <div className="form-group">
                    <label>Stad <span className="required">*</span></label>
                    <input type="text" name="bill-city" id="bill-city" className="form-control" onChange={e => setbill_City(e.target.value)} required value={city} />
                  </div>
                  <div className="form-group">                                        
                    <label>Staat</label>
                    <input type="text" name="bill-state" id="bill-state" className="form-control" onChange={e => setbill_State(e.target.value)} value={state} />
                  </div>
                  <div className="form-group">
                    <label>Postcode <span className="required">*</span></label>
                    <input type="text" name="bill-zip" id="bill-zip" className="form-control" onChange={e => setbill_Zipcode(e.target.value)} required value={zipcode} />
                  </div>
                  <div className="form-footer mb-0">
                    <div className="form-footer-right">
                      <button type="submit" className="btn btn-dark py-4">
                        Opslaan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>{/* End .tab-pane */}
            <div className="tab-pane fade" id="billing" role="tabpanel">
              <div className="address account-content mt-0 pt-2">
                <h4 className="title">Afleveradres</h4>
                <div className="App col-md-12">
                  <Alert variant={Variant} show={AlertText ? true : false}>
                    {AlertText}
                  </Alert>
                </div>
                <form className="mb-2" action="#" onSubmit={handlebillingaddressSubmit}>
                  <div className="select-custom">
                    <label>Land <span className="required">*</span></label>
                    <select className="form-control"
                      id="country"
                      name="country"
                      disabled={false}
                      onChange={e => setbill_Country(e.target.value)}
                      required
                    >
                      <option value="">
                        Select land
                      </option>
                      {optionList.map((item) => (
                        <option value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Adres <span className="required">*</span></label>
                    <input type="text" name="bill-address" id="bill-address" className="form-control" placeholder="Straatnaam + huisnummer" onChange={e => setbill_Address(e.target.value)} required />
                  </div>
                  <div className="form-group">
                    <label>Stad <span className="required">*</span></label>
                    <input type="text" name="bill-city" id="bill-city" className="form-control" onChange={e => setbill_City(e.target.value)} required />
                  </div>
                  <div className="form-group">
                    <label>Staat</label>
                    <input type="text" name="bill-state" id="bill-state" className="form-control" onChange={e => setbill_State(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>Postcode <span className="required">*</span></label>
                    <input type="text" name="bill-zip" id="bill-zip" className="form-control" onChange={e => setbill_Zipcode(e.target.value)} required />
                  </div>
                  <div className="form-footer mb-0">
                    <div className="form-footer-right">
                      <button type="submit" className="btn btn-dark py-4">
                        OPSLAAN
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>{/* End .tab-pane */}
          </div>{/* End .tab-content */}
        </div>{/* End .row */}
      </div>{/* End .container */}
      <div className="mb-5" />{/* margin */}
    </main>
  )
}
