import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Container, Alert} from 'react-bootstrap';

export default function Footer() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [categoryList, setCategoryList] = useState(null);
  const userCartState = useSelector(state => state?.getUserCart);
  const [email, setEmail] = useState("");
  const [subscriptionResp, setSubscriptionResp] = useState(null);

  const fetchCategoryList = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}get-categories`); 
    setCategoryList(data);
  }

  const categoryListHandler = (slug, id) => {
    localStorage.removeItem('selectcat');
    localStorage.setItem('selectcat', slug);
    window.location.href = "/products";
  }

  const handleEmailSubscription = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}news-subscription`, {
      email,
    });
    setSubscriptionResp(data);
    setTimeout(() => {
      setSubscriptionResp(null);
    }, 1000);
  }
  useEffect(() => {
    fetchCategoryList();
  }, []);

  return (
    <>
      <footer className="footer position-relative">
        <div className="footer-middle">
          <div className="container-fluid position-static">
            <div className="footer-ribbon text-white ls-0">
                Get in touch
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="widget">
                    <h4 className="widget-title font-weight-bold">Contact Informatie</h4>
                    <ul className="contact-info">
                        <li>
                            <span className="contact-info-label">Adres:</span>
                                Unity Trading B.V.<br/>
                                Abberdaan 210, <br />
                                1046AB Amsterdam,  <br />
                                KVK: 80337422, <br />
                                BTW: NL861636168B01<br/>
                        </li>
                        <li>
                            <span className="contact-info-label">Tel:</span><a href="tel:">(085) - 016 13 60 </a>
                        </li>
                        <li>
                            <span className="contact-info-label">E-mail:</span> <a href="mailto:info@techbyte.nl">info@techbyte.nl</a>
                        </li>
                        <li>
                            <span className="contact-info-label">Werkdagen/uren:</span> Ma - Vrij / 9:00 - 17:00
                        </li>
                    </ul>
                    {/* <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=61552883525559" className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook" />
                        <a href="https://www.instagram.com/tvgigant/" className="social-icon social-instagram icon-instagram" target="_blank" title="instagram" />
                    </div> */}
                </div>
                {/* End .widget */}
              </div>
              {/* End .col-lg-3 */}
              <div className="col-lg-9 col-md-8">
                            <div className="widget widget-newsletter">
                                <h4 className="widget-title">ABONNEER OP NIEUWSBRIEF</h4>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 mb-1">
                                        <p className="mb-0">Ontvang alle laatste informatie over evenementen, verkopen en aanbiedingen. Schrijf je vandaag in voor de nieuwsbrief.</p>
                                    </div>
                                    <div className="col-lg-6 col-md-12 mb-1">
                                        <form action="#" className="d-flex mb-0 w-100" onSubmit={handleEmailSubscription}>
                                            <input type="email" className="form-control mb-0" placeholder="E-mail Adres" value={email} onChange={(e) => setEmail(e.target.value)} required/>

                                            <input type="submit" className="btn btn-primary shadow-none" value="Abonneren"/>
                                        </form>
                                        <Alert variant={"success"} show={subscriptionResp ? true : false}>
                                            {subscriptionResp?.message}
                                        </Alert> 
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5 col-lg-12 col-md-12">
                                    <div className="widget">
                                        <h4 className="widget-title mb-1">Klantenservice</h4>

                                        <div className="links link-parts row">
                                            <ul className="link-part col-lg-4 col-md-12 mb-0">
                                                <li><a href="/about-us">Over Ons</a></li>
                                                <li><a href="/contact-us">Contact</a></li>
                                                <li><a href="/my-account">Mijn Account</a></li>
                                            </ul>
                                            <ul className="link-part col-lg-4 col-md-12">
                                                <li><a href="/terms-and-conditions">Algemene Voorwaarden</a></li>
                                                <li><a href="/register">Klant Worden</a></li>
                                                <li><a href="/login">Log In</a></li>
                                            </ul>
                                            <ul className="link-part col-lg-4 col-md-12">
                                                <li><a href="/">Home</a></li>
                                                <li><a href="/products">Producten</a></li>
                                                <li><a href="/wishlist">Verlanglijst</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              {/* End .col-lg-9 */}
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .footer-middle */}
        <div className="container-fluid">
          <div className="footer-bottom d-sm-flex align-items-center">
            <div className="footer-left">
              <span className="footer-copyright">© auteursrechten 2024. Alle rechten voorbehouden.</span>
            </div>
            <div className="footer-right ml-auto mt-1 mt-sm-0">
              <div className="payment-icons mr-0">
                <img className="payment-icon ideal" src={`${process.env.PUBLIC_URL}/custom/assets/images/payments/ideal.png`} />
              </div>
            </div>
          </div>
        </div>
        {/* End .footer-bottom */}
      </footer>
      {/* End .footer */}
      <div className="mobile-menu-overlay" />
      <div className="mobile-menu-container">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="fa fa-times" />
          </span>
          <nav className="mobile-nav">
            <ul className=" mobile-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a href="/">Categorieen</a>
                <ul className="cat-list">
                  {
                    categoryList ? categoryList?.body?.map((list, i) => {
                      return (
                        <li key={i} >
                          <a href={`#widget-category-${i + 1}`}  className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls={`widget-category-${i + 1}`} >{list.name}
                          <span className="toggle" ></span>
                          </a>
                          <div className="collapse" id={`widget-category-${i + 1}`}>
                                <ul className="cat-sublist">
                            {
                              list.subCategory.map((subList, id) => <li key={id}>
                                <a href="#" onClick={() => categoryListHandler(subList.slug, subList._id)}>
                                  {subList.name}
                                </a>
                              </li>
                              )
                            }
                          </ul>
                          </div>
                        </li>
                      )
                    }) : null
                  }
                </ul>
              </li>
              <li>
                <Link to="/products">Producten</Link>
              </li>
              <li>
                <Link to="/about-us">Over Ons</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
              {(user?.token ? <>
                <li >
                  <Link to="/my-account" >Mijn Account</Link>
                </li>
              </> : <>
                <li>
                  <Link to="/register">Klant Worden</Link>
                </li>
              </>)}
            </ul>
          </nav>
          {/* End .mobile-nav */}
          {/* <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61552883525559" className="social-icon social-facebook icon-facebook" target="_blank"></a>
            <a href="https://www.instagram.com/tvgigant/" className="social-icon social-instagram icon-instagram" target="_blank"></a>
          </div> */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
      <div className="sticky-navbar">
        <div className="sticky-info">
          <a href="/">
            <i className="icon-home" />Home
          </a>
        </div>
        <div className="sticky-info">
          <a href="#" className data-toggle="modal" data-target="#exampleModalCenter">
            <i className="icon-bars" />Categorieen
          </a>
        </div>
        <div className="sticky-info">
          <a href="/wishlist" className>
            <i className="icon-wishlist-2" />Verlanglijst
          </a>
        </div>


        {(user?.token ? <>
          <div className="sticky-info">
          <a href="/my-account" className>
            <i className="icon-user-2" />Account
          </a>
        </div>
        </> : <>
        <div className="sticky-info">
          <a href="/login" className>
            <i className="icon-user-2" />Account
          </a>
        </div>
        </>)}

        <div className="sticky-info">
          <a href="/cart" title="Cart" className="dropdown-toggle dropdown-arrow cart-toggle fix-dropdown">
            <i className="minicart-icon"> </i>  
            <span className="cart-count badge-circle fixed-badge">{(userCartState?.cart?.totalQuantity ? userCartState?.cart?.totalQuantity : 0)}</span>
          </a>
        </div>
      </div >      
          <div className="modal fade" id="exampleModalCenter" tabIndex="-1"
              role="dialog" aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                      <div className="modal-header modal-fix ">
                          
                          <button type="button" className="close"
                              data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                <div className="modal-body row edits-model">
                  <div className="row ">
                  
                    {
                      categoryList ? categoryList?.body?.map((list, i) => {
                        return (
                          <div className="col-lg-4 heading-menu" key={i}>
                            <a href="#" className="nolink">{list?.name}</a>

                            {list.subCategory.length != '' ? 
                            <ul className="submenu mobile-edits">
                              {
                                list ? list?.subCategory?.map?.((subList, id) => {
                                  return (
                                    <li key={id} >
                                      <a href="#" onClick={() => categoryListHandler(subList.slug, subList._id)} >
                                        {/* <Link to ={"/products/"+subList.slug} state={{product_id:subList._id}}>
                                          {subList.name}
                                        </Link> */}
                                        {subList.name}
                                        </a> 
                                    </li>
                                  )
                                }) : 
                                    <li key={i} >
                                      <a href="#" >Binnenkort Beschikbaar</a>
                                    </li>
                              }
                            </ul>
                            : null}
                          </div>
                        )
                      }) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
