import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getUserCart, removeItemFromCart, emptyCart } from "../../action/cartActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from '../common/Loader';
import axiosIntance from "../../helper/axios"; 
import { removeLocalStorage } from "../common/utils.js"

const Checkout = () => {

    const user = JSON.parse(localStorage.getItem('user'));
    let applicableDiscount =  localStorage.getItem('discount');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userCartState = useSelector(state => state?.getUserCart);
    const updatedCart = useSelector(state => state?.removedFromCart);
    const emptyCartState = useSelector(state => state?.emptyCart);
    const [addresslist, setAddressList] = useState([]);
    const [responseData, setResponseData] = useState(null);
    const [paynlResponseData, setPaynlResponseData] = useState(null);
    const userId = localStorage.getItem("userId");
    const [selectedOption, setSelectedOption] = useState('');
    const [shippingOption, setshippingoption] = useState('');
    const [error, setError] = useState('');
    const [billaddresslist, setBillAddressList] = useState([]);
    const addressurl = process.env.REACT_APP_BASE_URL + 'get-address-list'; 
    const [loader, setLoader] = useState(false);
    const [taxAmt, setTaxAmount] = useState(0);
    const [paymentMode, setPaymentMode] = useState(); 
    const [shippingAddressLength, setShippingAddressLength] = useState(0); 
    
    const [usrBillingAddress, setUsrBillingAddressList] = useState({
        address: "",
        city: "",
        countryCode: "",
        countryId: "",
        countryName: "",
        id: "",
        state: "",
        zipcode: ""
    });
    const [usrShippingAddress, setUsrShippingAddress] = useState({
        address: "",
        addressId: "",
        city: "",
        countryCode: "",
        countryId: "",
        countryName: "",
        isDefault: 0,
        state: "",
        zipcode: ""
    })

    const [totalPrice, setTotalPrice] = useState(0);
    const [deductableAmount, setDeductableAmount] = useState(0);
    const [cartListData,setCartListData] = useState([]);
    const [thkListData,setTHKListData] = useState([]);

    const [shippingAddress, setPerson_Shipping_Address] = useState('');
    const [shippingCountry, setpPerson_Shipping_Country] = useState();
    const [shippingCity, setPerson_Shipping_City] = useState();
    const [shippingState, setPerson_Shipping_State] = useState();
    const [shippingZipcode, setPerson_Shipping_Zipcode] = useState();

    const [optionList,setOptionList] = useState([]);
    const [dcaState,setDCAState] = useState(false);
    const [addCheckedO,setAddheckedO] = useState(true);
    const [taxValue,setTaxValue] = useState(0);

    var totalTHKPrice = 0;
    var totTHKPrice = 0;

    useEffect(()=>{
        getCartList()
    },[])

    useEffect(()=>{
        fetchcData();
    },[])

    const cuntryurl = process.env.REACT_APP_BASE_URL + 'get-countries';

    const fetchcData = () => {
        axios
        .get(cuntryurl)
        .then((response) => {
        const { data } = response;
        if(response.data.success === 1){
        //check the api call is success by stats code 200,201 ...etc
        setOptionList(data.body)
        }else{
        //error handle section 
        }
        })
        .catch((error) => {});
    };

    
    const getCartList = async () => {
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-cart-list`, {userId:userId}).then(res=>{
            setTaxValue(res?.data?.body?.tax);
            setCartListData(res?.data?.body?.cartResult);
            setTHKListData(res?.data?.body?.thkResult);
        }).catch(err=>{
            console.log(err)
        })
    }

    var productsSubtotal = cartListData?.reduce((accum,item) => accum + parseFloat(item.productPrice.$numberDecimal * item.productQty), 0)
    if(thkListData.length>0){
        totalTHKPrice = thkListData?.reduce((accum,item) => accum + parseFloat(item.thkAmount.$numberDecimal * item.thkProdQty), 0)
    }
    var totalProductPrice = parseFloat(productsSubtotal)+parseFloat(totalTHKPrice);
    var afterDiscount = parseFloat(totalProductPrice) - parseFloat(deductableAmount);
    var taxAmount = parseFloat(parseFloat(afterDiscount)) * parseFloat(taxValue) /100;
    var AmountIncludingTax = parseFloat(afterDiscount) + parseFloat(taxAmount);
   
    const fetchcaddressData = () => { 
        axiosIntance.post(addressurl, {
            userId: userId
        })
            .then((response) => { 
                const { data } = response;
                if (response.data.success === 1) { 
                    
                    setShippingAddressLength(data.body.shippingAddress.length);
                    if(data.body.shippingAddress.length == 1){
                        setshippingoption(data.body.shippingAddress[0]?.addressId);
                        setUsrShippingAddress({
                            address: data.body.shippingAddress[0]?.address,
                            addressId: data.body.shippingAddress[0]?.addressId,
                            city: data.body.shippingAddress[0]?.city,
                            countryCode: data.body.shippingAddress[0]?.countryCode,
                            countryId: data.body.shippingAddress[0]?.countryId,
                            countryName: data.body.shippingAddress[0]?.countryName,
                            isDefault: data.body.shippingAddress[0]?.isDefault,
                            state: data.body.shippingAddress[0]?.state,
                            zipcode: data.body.shippingAddress[0]?.zipcode
                        })
                    }
                    setAddressList(data.body.shippingAddress)
                    setBillAddressList(data.body.billingAddress)

                    setUsrBillingAddressList({
                        address: data.body.billingAddress[0].address,
                        city: data.body.billingAddress[0].city,
                        countryCode: data.body.billingAddress[0].countryCode,
                        countryId: data.body.billingAddress[0].countryId,
                        countryName: data.body.billingAddress[0].countryName,
                        id: data.body.billingAddress[0].id,
                        state: data.body.billingAddress[0].state,
                        zipcode: data.body.billingAddress[0].zipcode
                    });
                    setSelectedOption("billing" + data.body.billingAddress[0].id)
                } else {
                    //error handle section 
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        dispatch(getUserCart());
    }, [updatedCart, emptyCartState]);

    useEffect(() => {
        let discountType = localStorage.getItem('discountType');
        fetchcaddressData();
        if (applicableDiscount != 0 && applicableDiscount != null) { 
            setTaxAmount(taxAmount); 
            var prodSubtoal = cartListData?.reduce((accum,item) => accum + parseFloat(item.productPrice.$numberDecimal * item.productQty), 0)
            if(thkListData.length>0){
                totTHKPrice = thkListData?.reduce((accum,item) => accum + parseFloat(item.thkAmount.$numberDecimal * item.thkProdQty), 0)
            }
            var totAmount   = parseFloat(prodSubtoal)+parseFloat(totTHKPrice);

            if(applicableDiscount){
                if(discountType=='percent'){
                    setDeductableAmount( parseFloat(applicableDiscount) * parseFloat(totAmount)/100 ); 
                    setTotalPrice(AmountIncludingTax);
                }else if(discountType=='fixed'){
                    setDeductableAmount((parseFloat(applicableDiscount)));
                    setTotalPrice(AmountIncludingTax);
                }
            }
            
        } else {
            var totalProductPrice = userCartState?.cart?.items?.reduce((accum,item) => accum + parseFloat(item.price * item.quantity), 0)
            setTotalPrice(AmountIncludingTax);
        }
    }, [])

   const handleDCA = (e)=>{
    console.log(e.target.checked)
    if(e.target.checked){
        setshippingoption('123');
        setAddheckedO(false)
        setDCAState(true)

        setUsrShippingAddress({
            address: '',
            addressId: '',
            city: '',
            countryCode: '',
            countryId: '',
            countryName: '',
            isDefault: '',
            state: '',
            zipcode: ''
        })

    }else{
        setDCAState(false);
    }
   }
    const handleBillingAddress = (e, recordAddress, recordCity, recordCountryCode, recordCountryId, recordCountryName, recordId, recordState, recordZipcode) => {
        setSelectedOption(e.target.id);
        setError('');
        setUsrBillingAddressList({
            address: recordAddress,
            city: recordCity,
            countryCode: recordCountryCode,
            countryId: recordCountryId,
            countryName: recordCountryName,
            id: recordId,
            state: recordState,
            zipcode: recordZipcode
        })

    };

    const handleShippingAddress = (e, recordAddress, recordAddressId, recordCity, recordCountryCode, recordCountryId, recordCountryName, recordIsDefault, recordState, recordZipcode) => {
        setshippingoption(e.target.id);
        setDCAState(false);
        setAddheckedO(true)
        setError('');
        setUsrShippingAddress({
            address: recordAddress,
            addressId: recordAddressId,
            city: recordCity,
            countryCode: recordCountryCode,
            countryId: recordCountryId,
            countryName: recordCountryName,
            isDefault: recordIsDefault,
            state: recordState,
            zipcode: recordZipcode
        })
    };
   
    const createOrderHandler = async () => { 

        if (selectedOption != '' && shippingOption != '') { 

            if(!paymentMode){
                setError('Selecteer uw betaalmethode.');
                return false;
            }

            let orderList = [];
            let subTotal = 0;
            let userOrderId = "";
            let userReferenceId = "";

            setLoader(true);

            for (const items of cartListData) {
                orderList.push({
                    itemId: items.itemId,
                    itemPrice: Number(items.price),
                    itemQty: items.quantity,
                    totalAmount: Number(items.subTotal),
                    discount: applicableDiscount,
                    orderNotes: "xyz"
                });
                subTotal += Number(items.subTotal)
            };

            const orderObj = {
                userId,
                tax: taxAmount,
                taxPercent:taxValue,
                subTotal:totalProductPrice,
                totalAmount: AmountIncludingTax,
                discount: applicableDiscount,
                discountType: localStorage.getItem('discountType'),
                coupon: localStorage.getItem('coupon'),
                orderDiscount:deductableAmount,
                address: usrBillingAddress.address,
                country: usrBillingAddress.countryId,
                countryName: usrBillingAddress.countryName,
                countryCode: usrBillingAddress.countryCode,
                state: usrBillingAddress.state,
                city: usrBillingAddress.city,
                zipcode: usrBillingAddress.zipcode,
                shippingAddress: usrShippingAddress.address ? usrShippingAddress.address : shippingAddress,
                shippingCountry: usrShippingAddress.countryId ? usrShippingAddress.countryId : shippingCountry,
                shippingCountryName: usrShippingAddress.countryName,
                shippingCountryCode: usrShippingAddress.countryCode,
                shippingState: usrShippingAddress.state ? usrShippingAddress.state : shippingState,
                shippingCity: usrShippingAddress.city ? usrShippingAddress.city : shippingCity,
                shippingZipcode: usrShippingAddress.zipcode ? usrShippingAddress.zipcode : shippingZipcode,
                isDCA: dcaState,
                userLevelId: null,
                orderItems: orderList,
                companyName:user.companyName,
                thkNumber:user.thuiskopieNumber
            }

            await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}create-order`,  orderObj).then(async response => {
                setResponseData(response);
                userOrderId = response?.data?.body?.orderId;
                userReferenceId = response?.data?.body?.referenceId;
                removeLocalStorage();
                if(paymentMode==1){
                await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}payment-process`, {
                    userId: userId, orderId:userOrderId
                }).then(response => {
                    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    setPaynlResponseData(response);
                    document.cookie = `paymentMode=${paymentMode};`
                    document.cookie = `orderId=${userOrderId};`
                    document.cookie = `referenceId=${userReferenceId};`
                    document.cookie = `paymentId=${response?.data?.body.transaction.transactionId};`
                    document.cookie = `totalPaidAmount=${totalPrice}`
                    window.location.href = response?.data?.body?.transaction?.paymentURL;
                }).catch(err => {});
            } else {
                    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    setPaynlResponseData(response);
                    document.cookie = `paymentMode=${paymentMode};`
                    document.cookie = `orderId=${userOrderId};`
                    document.cookie = `referenceId=${userReferenceId};`
                    document.cookie = `paymentId=`
                    document.cookie = `totalPaidAmount=${totalPrice}`
                    window.location.href="/payment-success";
            }
            }).catch(err => {
                console.log('Err',err)
            });
        }
        else {
            setError('Selecteer een factuuradres en afleveradres');
        }

    }

   
    return (
        <>
        <Loader loading={loader} />
            <main className="main">
                <div className="container">
                    <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                        <li>
                            <a href="/cart">Winkelwagen</a>
                        </li>
                        <li className="active">
                            <a href="#">Afrekenen</a>
                        </li>
                        <li className="disabled">
                            <a href="#">Bestelling Voltooid</a>
                        </li>
                    </ul>

                    {cartListData?.length>0 ?
                        <div className="row">                          

                            <div className="col-lg-7">
                                <div className="cart-summary">
                                    <table className="table table-totals">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" className="text-left">
                                                    <h4>Factuuradres</h4>
                                                    <hr />
                                                    <div className="row">
                                                        {billaddresslist.map((record, i) => (
                                                            record.address.length > 0
                                                                ? (<div className="col-12 col-md-12 address-block" key={i}>

                                                                    <div className="col-md-12 bill-cont">
                                                                        <div className="circle-icon" ><span className="col-md-3">
                                                                            <input type="radio" className="custom-control-input radio-btn" name="radio-billing" id={"billing" + record.id} value={record.id} onChange={(e) => handleBillingAddress(e, record.address, record.city, record.countryCode, record.countryId, record.countryName, record.id, record.state, record.zipcode)} checked={true} /></span></div>
                                                                        <div className="bill-content">
                                                                            <div>{record.address}</div>
                                                                            <div>
                                                                                <span>{record.state}</span>
                                                                                <span>{record.city}</span>
                                                                                <span>{record.countryName}</span>
                                                                                <span>{record.zipcode}</span></div>
                                                                        </div>
                                                                    </div></div>)
                                                                : (
                                                                    <div>U heeft dit type adres nog niet ingesteld.</div>
                                                                )
                                                        ))}


                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="cart-summary shiping">
                                    <div className="text-left">
                                        <h4>Afleveradres</h4>
                                        <hr />
                                        <div className="row">
                                            { addresslist.length >0 ? 
                                            <>
                                            {addresslist.map((record, i) => (
                                                record.address.length > 0
                                                    ? (<div className="col-12 col-md-12 address-block" key={i}>
                                                        <div className="col-md-12 bill-cont">
                                                            <div className="circle-icon">
                                                                <span className="col-md-3">
                                                                {shippingAddressLength == 1 ?
                                                                <>
                                                                    <input type="radio" className="custom-control-input radio-btn" name="radio-shipping" id={'shipping' + record.addressId} value={record.addressId} onChange={(e) => handleShippingAddress(e, record.address, record.addressId, record.city, record.countryCode, record.countryId, record.countryName, record.isDefault, record.state, record.zipcode)} checked={addCheckedO ? true : false}/> 
                                                                </>
                                                                :
                                                                <>
                                                                    <input type="radio" className="custom-control-input radio-btn" name="radio-shipping" id={'shipping' + record.addressId} value={record.addressId} onChange={(e) => handleShippingAddress(e, record.address, record.addressId, record.city, record.countryCode, record.countryId, record.countryName, record.isDefault, record.state, record.zipcode)} checked={shippingOption === 'shipping' + record.addressId} /> 
                                                                </>
                                                                }
                                                                </span><br />
                                                            </div>
                                                            <div className="ship-content">
                                                                <div>{record.address}</div>
                                                                <div><span>{record.state}</span><span>{record.city}</span><span>{record.countryName}</span><span>{record.zipcode}</span></div>
                                                            </div>
                                                        </div></div>)
                                                    : (
                                                        <div>U heeft dit type adres nog niet ingesteld.</div>
                                                    )
                                            ))}
                                            </>
                                            :
                                            <>
                                            <p>Geen afleveradres gevonden.<a href='/my-account' style={{color:"#308B7B"}}>Klik hier om aan te maken</a> </p>
                                            </>
                                            }

                                            <div className="col-12 col-md-12 address-block">
                                                <div className="col-md-12 bill-cont">
                                                    <div className="circle-icon">
                                                        <span className="col-md-3">
                                                            <input type="radio" className="custom-control-input radio-btn" name="radio-shipping1" onChange={handleDCA} value={dcaState} checked={shippingOption == '123' ? true : false}/> 
                                                        </span>
                                                    </div>
                                                    <div className="ship-content">
                                                        <div>Rechtstreeks naar de klant</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    { dcaState ?
                                    <>
                                        <div className="shipping-address">
                                            <br/>
                                            <div className='form-styl'>
                                            <div className='inner-style'>
                                                <label className="form-label">Adres<span className="required">*</span></label>
                                                <input type="text" className="form-control" value={shippingAddress} id="shippingAddress" name="shippingAddress" onChange={e => setPerson_Shipping_Address(e.target.value)}  required/>
                                            </div>
                                            <div className='inner-style'>
                                                <label className="form-label">Land<span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    id="shippingCountry" 
                                                    name="shippingCountry"
                                                    disabled={false}
                                                    value={shippingCountry}
                                                    onChange={e => setpPerson_Shipping_Country(e.target.value)}
                                                    required
                                                    >
                                                    <option value="">
                                                        Selecteer land
                                                    </option> 
                                                        {optionList.map((item) => (
                                                        <option value={item.id}>
                                                            {item.name}
                                                        </option>
                                                        ))}
                                                </select>
                                            </div>

                                            </div>

                                            <div className='form-styl'>
                                            <div className='inner-style'>
                                                <label className="form-label">Stad<span className="required">*</span></label>
                                                <input type="text" className="form-control" value={shippingCity} id="shippingCity" name="shippingCity" onChange={e => setPerson_Shipping_City(e.target.value)}  required/>
                                            </div>
                                            <div className='inner-style'>
                                                <label className="form-label">Staat</label>
                                                <input type="text" className="form-control" value={shippingState} id="shippingState" name="shippingState" onChange={e => setPerson_Shipping_State(e.target.value)}  />
                                            </div>
                                            </div>
                                            <div className='form-styl-fullwidth'>                    
                                                <label className="form-label ">Postcode<span className="required">*</span></label>
                                                <input type="text" className="form-control" value={shippingZipcode} id="shippingZipcode"  name="shippingZipcode" onChange={e => setPerson_Shipping_Zipcode(e.target.value)} required />
                                            </div>

                                        </div>
                                    </>
                                    :''}
                                </div>                                
                            </div>

                            <div className="col-lg-5">
                            <div className="cart-summary order-total">
                                    <div className="row">
                                        {
                                            applicableDiscount != 0 ? (
                                                <>
                                                    <div className="col-12 order-box"  >
                                                        <label style={{ fontSize: "0.75rem" }} >Subtotaal</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(totalProductPrice).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div>
                                                    { localStorage.getItem('coupon') != null ?
                                                    <>
                                                        <div className="col-12 order-box">
                                                        { localStorage.getItem('discountType')=='percent' ? 
                                                            <label style={{ fontSize: "0.75rem" }} >Coupon Korting ( {applicableDiscount}% )</label>
                                                            :<label style={{ fontSize: "0.75rem" }} >Coupon Korting</label> }
                                                            <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(deductableAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        </div>
                                                    </>
                                                    :''}
                                                    <div className="col-12 order-box">
                                                        <label style={{ fontSize: "0.75rem" }} >Belasting ( {taxValue}% )</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(parseFloat(taxAmount)).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div> 


                                                    <div className="col-12 order-box">
                                                        <label>Order Totaal</label>
                                                        <p>{process.env.REACT_APP_CURRENCY}{((Number(AmountIncludingTax)).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div>
                                                </>
                                            ) : (
                                               <>
                                               <div className="col-12 order-box"  >
                                                    <label style={{ fontSize: "0.75rem" }} >Subtotaal</label>
                                                    <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(totalProductPrice).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                               <div className="col-12 order-box">
                                                    <label style={{ fontSize: "0.75rem" }} >Belasting ( {taxValue}% )</label>
                                                    <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(parseFloat(taxAmount)).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>

                                                <div className="col-12 order-box">
                                                    <label>Order Totaal</label>
                                                    <p>{process.env.REACT_APP_CURRENCY}{(Number(AmountIncludingTax).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                               </>
                                            )
                                            
                                        }
                                         
                                        <div className="col-12 order-box" style={{marginTop:"26px"}}>
                                            <div className="row">
                                                <div className="col-12"><label style={{ fontSize: "0.71rem" }} >Betaalmethode</label></div>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{marginTop:"8px"}}>
                                            <div className="row" style={{width:"100%"}}>
                                                <div className="col-4"><label>iDeal</label> <input type="radio" name="payment" className="radio-btn" value={1} onChange={()=>{setPaymentMode(1)}}/></div>
                                                <div className="col-8"><label>Bankoverschrijving</label> <input type="radio" name="payment" className="radio-btn" value={1} onChange={()=>{setPaymentMode(2)}}/></div>
                                            </div>
                                         </div>
                                    </div>
                                    
                                    {error && <div style={{ color: 'red' }}>{error}</div>} 

                                    {(user?.token ? <>
                                        <div className="checkout-methods">
                                            <a href="#" className="btn btn-block btn-dark"
                                                onClick={() => createOrderHandler()}
                                            >Afrekenen
                                            <i className="fa fa-arrow-right"></i></a>
                                        </div>
                                    </> : <>
                                        <div className="checkout-methods">
                                            <a href="/login" className="btn btn-block btn-dark">Afrekenen
                                            <i className="fa fa-arrow-right"></i></a>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cart-table-container">
                                    <div className="cart-empty-table text-center "><h3>Winkelwagen Leeg</h3>
                                        <h5 className="text-center" >Niks gevonden in winkelwagen</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="mb-6"></div>
            </main>
        </>
    )
}

export default Checkout;