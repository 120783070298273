import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from 'uuid';
import { getUserCart, removeItemFromCart, emptyCart, addToCartAction } from "../../action/cartActions"; 
import axios from "axios";
import Loader from '../common/Loader';
import { Link } from "react-router-dom";
import { set } from "react-hook-form";
import axiosIntance from '../../helper/axios';
import { removeCookies } from "../common/utils.js";

const Cart = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let applicableDiscount = localStorage.getItem('discount');
    let customerLevelName = JSON.parse(localStorage.getItem("userType"))?.customerLevelName;
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();    
    const userCartState = useSelector(state => state?.getUserCart);
    const updatedCart = useSelector(state => state?.removedFromCart);
    const emptyCartState = useSelector(state => state?.emptyCart);
    const [error, setError] = useState('');  
    const userId = localStorage.getItem("userId");         
    const [prodQty, setProdQty] = useState();
    const [incrementValue, setIncrementValue] = useState([]);
    const [randomNo, setRandomNo] = useState(0);
    const addressurl = process.env.REACT_APP_BASE_URL + 'get-address-list';     
    const [totalPrice, setTotalPrice] = useState(0);
    const [deductableAmount, setDeductableAmount] = useState(0);
    const [coupon,setCoupon] = useState('');
    const [couponApplied,setCouponApplied] = useState(null);
    const [cartListData,setCartListData] = useState([]);
    const [thkListData,setTHKListData] = useState([]);
    const [cartListDataStatus,setCartListDataStatus] = useState(false);
    const [taxValue,setTaxValue] = useState(0);

    var totalTHKPrice = 0;

    useEffect(()=>{
        getCartList()
    },[cartListDataStatus])

   
    const getCartList = async () => {
        setLoader(true)
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-cart-list`, {userId:userId}).then(res=>{
            setTaxValue(res?.data?.body?.tax);
            setCartListData(res?.data?.body?.cartResult);
            setTHKListData(res?.data?.body?.thkResult);
            setLoader(false)
        }).catch(err=>{
            console.log(err)
        })
    }
   
    useEffect(()=>{
        setCoupon(localStorage.getItem('coupon'));
        setCouponApplied(localStorage.getItem('coupon'));
        removeCookies();
    },[])

    useEffect(() => {
        dispatch(getUserCart());
    }, [updatedCart, emptyCartState]);

    useEffect(() => { 
        let discountType = localStorage.getItem('discountType');
        if (applicableDiscount != 0 && applicableDiscount != null) {  
        //    var totAmount = userCartState?.cart?.items?.reduce((accum,item) => accum + parseFloat(item.price * item.quantity), 0)
            var prodSubtoal = cartListData?.reduce((accum,item) => accum + parseFloat(item.productPrice.$numberDecimal * item.productQty), 0)
            var totTHKPrice = thkListData?.reduce((accum,item) => accum + parseFloat(item.thkAmount.$numberDecimal * item.thkProdQty), 0)
            var totAmount   = parseFloat(prodSubtoal)+parseFloat(totTHKPrice);
           if(applicableDiscount){
                if(discountType=='percent'){
                    setDeductableAmount(parseFloat(applicableDiscount) * parseFloat(totAmount) / 100);
                    setTotalPrice(parseFloat(totAmount) - ((applicableDiscount / 100) * parseFloat(totAmount)));
                }else if(discountType=='fixed'){
                    setDeductableAmount((parseFloat(applicableDiscount)));
                    setTotalPrice(parseFloat(totAmount) - parseFloat(applicableDiscount));
                }
                
            } else {
                setDeductableAmount(0);
                setTotalPrice(parseFloat(totAmount));
            }
        } 
        else {
            setTotalPrice(parseFloat(totAmount))
        }
    })

    const removeItemFromCartHandler = async (cartid,itemid) => {
        setLoader(true)
        let formData ={
            userId:userId,
            cartId:cartid
        }
    
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}cart-item-delete`, {formData}).then(res=>{

            localStorage.removeItem("totalCartQty");
            if(res.data.success==1){
                if(res.data.body.cartQty>0){
                    setLoader(false)
                    localStorage.setItem("totalCartQty",res.data.body.cartQty);
                    dispatch(removeItemFromCart(itemid));
                    setCartListDataStatus(true);
                    window.location.reload();
                }else{
                    setLoader(false)
                    localStorage.setItem("totalCartQty",res.data.body.cartQty);
                    dispatch(removeItemFromCart(itemid));

                    window.location.reload();
                }
            }else{
                setLoader(false)
                alert(res.data.message);
            }
        }).catch(err=>{
            console.log(err)
        })

    }
   
    
    const addToCartHandler = (totalPrice, shippingDetails, item, totalQuantity, type) => { 
        setLoader(true)
        let obj = incrementValue.find(o => o.product_id == item.itemId); 
        if (incrementValue == "" || incrementValue == 'undefined') {
        totalQuantity = prodQty;
        } else { 
        totalQuantity = obj.qty;
        item.quantity = obj.qty;
        } 
        dispatch(addToCartAction({ totalPrice, shippingDetails, item, totalQuantity, type }));
        setRandomNo(Math.random());
        window.location.reload(); 

    } 

    const cartManage = async (obj, type) => { 

        var cartData = cartListData?.reduce((accum,item) => accum + parseFloat(item.subTotal?.$numberDecimal), 0);
        setLoader(true); 
        let qty = 1;
        let totalPrice = (Number(obj?.productPrice?.$numberDecimal) * Number(qty)).toString();
        let shippingDetails = {};
        let totalQuantity = qty;  
        let item = {
            id: uuidv4(),
            itemId: obj?.proDetails?._id,
            thkId:obj?.proDetails?.thuiskopieId,
            product: obj?.proDetails?.name,
            quantity: qty,
            price: obj?.productPrice?.$numberDecimal,
            subTotal: obj?.subTotal?.$numberDecimal,
            size: "",
            imgPath: obj?.proDetails?.images[0],
            slug: obj?.proDetails?.slug
        } 

        const formData ={
                userId : userId,
                item:item,
                cartId:obj?._id,
                type:type
          };

        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}add-to-cart`, {formData}).then(res=>{
            if(res?.data?.success==1){
                localStorage.removeItem("totalCartQty");
                totalQuantity = res.data.body.totalCartQty;
                localStorage.setItem("totalCartQty",res.data.body.totalCartQty);
                addToCartHandler(totalPrice, shippingDetails, item, totalQuantity, type)
                setCartListDataStatus(true)
            }else if(res?.data?.body?.errNo == -100){
                alert("Nog maar 1 product op voorraad.");
                window.location.reload();
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    var productsSubtotal = cartListData?.reduce((accum,item) => accum + parseFloat(item.productPrice.$numberDecimal * item.productQty), 0)
    if(thkListData.length>0){
        totalTHKPrice = thkListData?.reduce((accum,item) => accum + parseFloat(item.thkAmount.$numberDecimal * item.thkProdQty), 0)
    }
    var totalProductPrice = parseFloat(productsSubtotal)+parseFloat(totalTHKPrice);
    var afterDiscount = parseFloat(totalProductPrice) - parseFloat(deductableAmount);
    var taxAmount = parseFloat(parseFloat(afterDiscount)) * parseFloat(taxValue)/100;
    var AmountIncludingTax = parseFloat(afterDiscount) + parseFloat(taxAmount);
    
 
    const handleCoupon = (e)=>{
        setCoupon(e.target.value)
    }
 
    const handleApplyCoupon = async ()=>{
     
        if(coupon!=''){
            setLoader(true)
            await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}get-coupon-details`, {coupon: coupon,userId:userId,subTotal:totalProductPrice})
            .then(result=>{
                if(result.data.code==400){
                    setLoader(false)
                    setError(result.data.message);
                    setCouponApplied(null)
                    setCoupon("")
                }
                else if(result.data.success==1){
                    setLoader(false)
                    setCouponApplied(coupon)
                    setError(null)
                    localStorage.setItem('coupon',coupon)
                    localStorage.setItem('discount',result.data.body.couponAmt)
                    localStorage.setItem('discountType',result.data.body.discountType)
                    window.location.reload();
                }else if(result.data.success==0){
                    setLoader(false)
                    setError(result.data.message);
                    setCouponApplied(null)
                    setCoupon("")
                }
            })
            
        }else{
            setError('Vul kortingscode in!');
        }
    }
    const handleRemoveCoupon = async ()=>{
        await axiosIntance.post(`${process.env.REACT_APP_BASE_URL}coupon-remove`, {coupon: coupon,userId:userId})
        .then(response=>{
            setCoupon("")
            setCouponApplied(null)
            localStorage.setItem('coupon','')
            localStorage.removeItem('discount')
            localStorage.removeItem('discountType')
            window.location.reload();
        }).catch(err=>{
            console.log('Error',err)
        })
      
    }
    return (
        <>
          <Loader loading={loader} />
            <main className="main">
                <div className="container">
                    <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                        <li className="active">
                            <a href="#">Winkelwagen</a>
                        </li>
                        <li>
                            <a href="#">Afrekenen</a>
                        </li>
                        <li className="disabled">
                            <a href="#">Bevestiging</a>
                        </li>
                    </ul>

                    {(cartListData?.length >0? <>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cart-table-container">
                                    <table className="table table-cart">
                                        <thead>
                                            <tr>
                                                <th className="thumbnail-col"></th>
                                                <th className="product-col">Producten</th>
                                                <th className="price-col">Prijs</th>
                                                <th className="qty-col">Kwantiteit</th>
                                                <th className="text-right">Subtotaal</th>
                                            </tr>
                                        </thead>

                                        
                                        <tbody>
                                            {
                                                cartListData ? (
                                                    cartListData?.map((cartList, i) => {
                                                        var qtyval = (cartList?.productQty ? cartList?.productQty : 1);
                                                        return (
                                                            <tr className="product-row" key={i} >
                                                                <td>
                                                                    <figure className="product-image-container">
                                                                        <Link to={"/product-details/"+cartList?.proDetails?.slug} className="product-image">
                                                                            <img src={cartList?.proDetails?.images[0]} alt="product" />
                                                                        </Link>
                                                                    </figure>
                                                                </td>
                                                                <td className="product-col">
                                                                    <h5 className="product-title">
                                                                        <Link to={"/product-details/"+cartList?.proDetails?.slug}>
                                                                            {cartList?.proDetails?.name}
                                                                        </Link>
                                                                    </h5>
                                                                </td>
                                                                <td>{(process.env.REACT_APP_CURRENCY + (Number(cartList.productPrice.$numberDecimal).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</td>
                                                                <td>
                                                                    <div className='product-single-qty'>
                                                                        <button className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => cartManage(cartList,'minus')}>-</button>
                                                                        <input value={qtyval} className="w-20 bg-sky-50 text-center py-1 mob-inp" readOnly onChange={(e) => setProdQty(e.target.value)} />
                                                                        <button className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" onClick={(e) => cartManage(cartList,'plus')}>+</button>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right"><span className="subtotal-price">
                                                                    {process.env.REACT_APP_CURRENCY}{(Number(cartList.productPrice.$numberDecimal * qtyval).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                                                <td className="action">
                                                                    
                                                                    <button className="btn btn-dark product-type-simple btn-shop"
                                                                        onClick={() => removeItemFromCartHandler(cartList._id,cartList.proDetails._id)}
                                                                    >
                                                                        Verwijderen
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : null
                                            }

{
                                                thkListData ? (
                                                    thkListData?.map((thkList, i) => {
                                                        return (
                                                            <tr className="product-row" key={i} >
                                                                <td>
                                                                    <h5 className="product-title">
                                                                        {thkList?.thkCode}
                                                                    </h5>
                                                                </td>
                                                                <td className="product-col">
                                                                    <h5 className="product-title">
                                                                        {thkList?.thkName}
                                                                    </h5>
                                                                </td>
                                                                <td>{(process.env.REACT_APP_CURRENCY + (Number(thkList?.thkAmount.$numberDecimal).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</td>
                                                                <td>
                                                                    <div className='product-single-qty'>
                                                                        <button className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" ></button>
                                                                        <input value={thkList?.thkProdQty} className="w-20 bg-sky-50 text-center py-1 mob-inp" readOnly />
                                                                        <button className="px-5 py-1 font-bold bg-gray-200 text-black prod-qty" ></button>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right"><span className="subtotal-price">
                                                                    {process.env.REACT_APP_CURRENCY}{(Number(thkList?.thkAmount.$numberDecimal * thkList?.thkProdQty).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></td>
                                                                <td className="action">
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : null
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="checkout-discount">
                                    <h4>Heb je een kortingscode?
                                        &nbsp;<button data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne" className="btn btn-link btn-toggle">VOER UW CODE IN</button>
                                    </h4>

                                    <div id="collapseTwo" className="collapse">
                                        <div className="feature-box">
                                            <div className="feature-box-content">
                                                <p>Als u een couponcode heeft, kunt u deze hieronder toepassen.</p>
                                                    <div className="input-group coupon-field">
                                                        <input 
                                                            type="text" 
                                                            className="form-control form-control-sm w-auto" 
                                                            placeholder="kortingscode" 
                                                            required="" 
                                                            value={coupon} onChange={handleCoupon}
                                                            disabled={ couponApplied == null || couponApplied == '' ? '' : 'disabled'}
                                                            />
                                                        <div className="input-group-append cope-pen-butn">
                                                            { (couponApplied == null || couponApplied =='') && (error != null || error != '' ) ?
                                                                <button title="Apply" onClick={handleApplyCoupon} className='btn btn-sm mt-0 btn-dark'>Indienen</button>
                                                                :
                                                                <button title="Remove" onClick={handleRemoveCoupon} className='btn btn-sm mt-0 btn-dark'>Verwijderen</button>
                                                            }
                                                            
                                                        </div>
                                                        {error != '' ?
                                                                <>
                                                                <br/>
                                                                <span style={{color:"red",fontSize:"15px"}}>{error}</span>
                                                                </>
                                                            :''}
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">                                                                
                                <div className="cart-summary order-total">
                                    <div className="row">
                                        {
                                            applicableDiscount != 0 &&  applicableDiscount!=null ? (
                                                <>
                                                    <div className="col-12 order-box"  >
                                                        <label style={{ fontSize: "0.75rem" }} >Subtotaal</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(totalProductPrice).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div> 

                                                    <div className="col-12 order-box">
                                                        { localStorage.getItem('discountType')=='percent' ? 
                                                        <label style={{ fontSize: "0.75rem" }} >kortingscode ( {applicableDiscount }%)</label>
                                                        :<label style={{ fontSize: "0.75rem" }} >kortingscode</label> }
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(deductableAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div> 

                                                    <div className="col-12 order-box">
                                                        <label style={{ fontSize: "0.75rem" }} >Belasting ( {taxValue}% )</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(parseFloat(taxAmount)).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div>


                                                    <div className="col-12 order-box">
                                                        <label>Order Totaal</label>
                                                        <p>{process.env.REACT_APP_CURRENCY}{((Number(AmountIncludingTax)).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                <div className="col-12 order-box"  >
                                                        <label style={{ fontSize: "0.75rem" }} >Subtotaal</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(totalProductPrice).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div> 

                                                 <div className="col-12 order-box">
                                                        <label style={{ fontSize: "0.75rem" }} >Belasting ( {taxValue}% )</label>
                                                        <p style={{ fontSize: "1.3rem" }}>{process.env.REACT_APP_CURRENCY}{(Number(taxAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>

                                                <div className="col-12 order-box">
                                                    <label>Volgorde Totaal</label>
                                                    <p>{process.env.REACT_APP_CURRENCY}{(Number(AmountIncludingTax).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                </div>
                                                </>
                                            )
                                        }
                                    </div>                                    
                                    {(user?.token ? <>
                                        <div className="checkout-methods">
                                            <a href="/cart-checkout" className="btn btn-block btn-dark"                                                
                                            >Afrekenen
                                            <i className="fa fa-arrow-right"></i></a>
                                        </div>
                                    </> : <>
                                        <div className="checkout-methods">
                                            <a href="/login" className="btn btn-block btn-dark">Afrekenen
                                                <i className="fa fa-arrow-right"></i></a>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </> : <>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cart-table-container">
                                    <div className="cart-empty-table text-center "><h3>Winkelwagen Leeg</h3>
                                        <h5 className="text-center" >Niks gevonden in winkelwagen</h5>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        </>)} 
                </div>
                <div className="mb-6"></div>
            </main>
        </>
    )
}

export default Cart